import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';

import LinkPills from '../LinkPills';
import SineWave from '../SineWave';

const ns = `hero-links`;

const HeroLinks = ({
	eyebrow,
	title,
	description,
	enableWave,
	links,
	isH1 = true,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--wave-enabled`]: enableWave,
	});

	return (
		<div className={rootClassnames}>
			<div className="container">
				<div className={`${ ns }__content`}>
					{eyebrow && (
						<p className={`${ ns }__eyebrow`}>
							{eyebrow}
						</p>
					)}
					{isH1 ? (
						<h1 className={`${ ns }__title h3`}>
							{title}
						</h1>
					) : (
						<h2 className={`${ ns }__title h3`}>
							{title}
						</h2>
					)}
					{description && (
						<div className={`${ ns }__description body-large`}>
							{parse(description)}
						</div>
					)}
				</div>
			</div>

			{links?.length > 0 && (
				<LinkPills
					links={links}
				/>
			)}

			{enableWave && (
				<SineWave
					containerId="hero-links-wave"
					canvasId="hero-links-wave-canvas"
				/>
			)}
		</div>
	);
};

export default HeroLinks;
