import React, {
	useMemo,
	useCallback,
	useState,
	useRef
} from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import uniqueId from 'uniqid';
import { decode } from 'js-base64';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getShopifyImage } from 'gatsby-source-shopify';

import { useStaticQuery, graphql } from 'gatsby';
import { StoreContext } from '../../context/store-context';
import { formatPrice } from '../../utils';

import NumericInput from '../NumericInput';
import Link from '../Link';
import RenderStaticHTML from '../RenderStaticHTML';

const ns = `line-item`;

const LineItem = ({ item, location, index }) => {
	const {
		removeLineItem,
		checkout,
		updateLineItem,
		loading,
	} = React.useContext(StoreContext);

	const [quantity, setQuantity] = useState(item.quantity);

	const subscriptionId = useRef();
	const sellingPlanGroup = useRef();

	const {
		wp: {
			globalSettings: {
				globalSettings: {
					cartSubscriptionDiscountText,
				},
			},
		},
	} = useStaticQuery(
		graphql`
			query {
				wp {					
					globalSettings {
						globalSettings {
							cartSubscriptionDiscountText
						}
					}
				}
			}
		`
	);

	// Setting up susbcription references above

	if (item.customAttributes.length) {
		item.customAttributes.forEach(({ key, value }) => {
			if (key === '_sellingPlanId') {
				subscriptionId.current = value;
			}
			if (key === '_sellingPlanGroup') {
				sellingPlanGroup.current = JSON.parse(value);
			}
		});
	}

	/**
	 * Item image
	 */

	const variantImage = {
		...item.variant.image,
		originalSrc: item.variant.image.src || item.variant.image.url || '',
	};

	const image = useMemo(() => {
		return getShopifyImage({
			image: variantImage,
			layout: 'constrained',
			crop: 'contain',
			width: 180,
			height: 180,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [variantImage.originalSrc]);

	/**
	 * Item price
	 */

	const formattedPrice = (p, d) => {
		const {
			currencyCode,
		} = item.variant.priceV2;

		if (d) {
			const discount = d / 100;
			const discountedPrice = (p - (p * discount));

			return formatPrice(
				currencyCode,
				discountedPrice
			);
		}

		return formatPrice(
			currencyCode,
			p
		);
	};

	/**
	 * Item remove
	 */

	const handleRemove = e => {
		e.preventDefault();
		removeLineItem(checkout.id, item.id, item, location, index);

		let dataLayerPrice = item.variant.price;

		if (subscriptionId && sellingPlanGroup.current) {
			dataLayerPrice -= (dataLayerPrice
				* (sellingPlanGroup.current.include.product.discount_amount / 100));
			dataLayerPrice = dataLayerPrice.toFixed(2);
		}
	};

	const uli = debounce(
		value => { updateLineItem(checkout.id, item.id, value, location); },
		300
	);

	/**
	 * Item quantity
	 */

	const debouncedUli = useCallback(value => {
		uli(value);
	// eslint-disable-next-line
	}, []);

	const handleQuantityChange = value => {
		if (value !== '' && Number(value) < 1) {
			return;
		}

		setQuantity(value);

		if (Number(value) >= 1) {
			debouncedUli(value);
		}
	};

	const doIncrement = () => {
		handleQuantityChange(Number(quantity || 0) + 1);
	};

	const doDecrement = () => {
		handleQuantityChange(Number(quantity || 0) - 1);
	};

	/**
	 * Item selected
	 */

	const renderSelectedOptions = title => {
		if (title === 'Default Title') return null;

		return (
			<div className={`${ ns }__opitons`}>
				{title.split('/').join('-')}
			</div>
		);
	};

	/**
	 * Subscription selected
	 */

	const renderSubscription = (spid, spg) => {
		if (!spg) return null;

		let output;

		spg.selling_plans.forEach(({
			selling_plan_id: sellingPlanId,
			selling_plan_name: sellingPlanName,
		}) => {
			if (sellingPlanId.toString() === spid?.split('/')?.pop()?.toString() || sellingPlanId.toString() === spid) {
				const spn = sellingPlanName
					.split(' ')
					.slice(2)
					.join(' ');

				output = (
					<div className={`${ ns }__subscription`}>
						{`Subscription - ${ spn }`}
						<RenderStaticHTML className={'wysiwyg'} html={cartSubscriptionDiscountText} />
					</div>
				);
			}
		});

		return output;
	};

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<li className={rootClassnames}>
			<div className={`${ ns }__left`}>
				{image && (
					<div className={`${ ns }__image`}>
						<GatsbyImage
							key={variantImage.src}
							image={image}
							alt={variantImage.altText ?? item.variant.title}
						/>
					</div>
				)}
			</div>
			<div className={`${ ns }__right`}>
				<div className={`${ ns }__right-container`}>
					<h4 className={`${ ns }__title h4`}>{item?.variant?.product?.title}</h4>
					<div className={`${ ns }__options-subscription`}>
						{renderSelectedOptions(item?.variant?.title)}
						{renderSubscription(subscriptionId.current, sellingPlanGroup.current)}
					</div>
					<div className={`${ ns }__quantity`}>
						<NumericInput
							disabled={loading}
							value={quantity}
							aria-label={`Quantity`}
							onIncrement={doIncrement}
							onDecrement={doDecrement}
							onChange={e => { handleQuantityChange(e.currentTarget.value); }}
						/>
					</div>
					<div className={`${ ns }__price h4`}>
						{formattedPrice(
							Number(item.variant.priceV2.amount),
							sellingPlanGroup.current?.include.product.discount_amount
						)}
					</div>
					<div className={`${ ns }__remove`}>
						{/* eslint-disable-next-line */}
						<Link onClick={e => { handleRemove(e); }}>Remove</Link>
					</div>
				</div>
			</div>
		</li>
	);
};

export default LineItem;
