import { get } from '.';
import klaviyo from './klaviyo';

export function trackViewedProduct(product, variant) {
	if (typeof window === 'undefined') {
		return;
	}

	const images = product.images || [];
	const variants = product.variants || [];
	const priceRange = product.priceRangeV2 || {};
	const productImages = images.map(image => image.image);

	if (!product.hasOnlyDefaultVariant) {
		productImages.splice(2, variants.length);
	}

	const productImage = get(productImages, '0.src');
  const productVariant = variant || variants[0] || {};
	const productPrice = productVariant?.price || priceRange.minVariantPrice?.amount;

	const viewedProduct = {
		ProductName: product.title,
		productID: product.shopifyId,
		ImageURL: productImage,
		URL: window.location.href,
		Brand: product.vendor || 'Nordic Naturals',
		Price: String(productPrice || ''),
		CompareAtPrice: String(productVariant.compareAtPrice || ''),
	};

	const viewedItem = {
		Title: product.title,
		ItemId: product.shopifyId,
		Categories: product.tags,
		ImageUrl: productImage,
		Url: window.location.href,
		Metadata: {
			Brand: product.vendor || 'Nordic Naturals',
			Price: String(productPrice || ''),
			CompareAtPrice: String(productVariant.compareAtPrice || ''),
		},
	};

	klaviyo.track('Viewed Product', viewedProduct);
	klaviyo.trackViewedItem(viewedItem);
}
