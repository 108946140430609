import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

import Link from '../Link';

const ns = 'link-pills';

const LinkPills = ({ links = [] }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const [initialSlide, setInitialSlide] = useState(-1);
	const sliderRef = useRef(null);

	const isBrowser = typeof window !== 'undefined';
	const pathname = isBrowser ? window.location.pathname : '';

	useEffect(() => {
		if (links?.length > 0) {
			const activeIndex = links.findIndex(link => pathname === link.url);
			setInitialSlide(activeIndex);
		}
	}, []);

	const goToSlide = (index) => {
		if (sliderRef.current) {
			sliderRef.current.slickGoTo(index);
		}
	};

	if (links.length === 0) return null;
	if (initialSlide === -1) return null;

	const settings = {
		arrows: false,
		infinite: false,
		initialSlide,
		slidesToScroll: 1,
		slidesToShow: 1,
		swipeToSlide: true,
		variableWidth: true,
	};

	const linksMarkup = links.map((link, index) => (
		<Link
			className={classNames({
				[`${ ns }__pill`]: true,
				[`${ ns }__pill--active`]: pathname === link.url,
			})}
			key={link.label}
			to={link.url}
			onClick={() => goToSlide(index)}
		>
			{link.label}
		</Link>
	));

	return (
		<div className={rootClassnames}>
			<div className="container">
				{/* Mobile */}
				<div className={`${ ns }__slider`}>
					<Slider
						ref={sliderRef}
						{...settings}
					>
						{linksMarkup}
					</Slider>
				</div>

				{/* Desktop */}
				<div className={`${ ns }__static`}>
					{linksMarkup}
				</div>
			</div>
		</div>
	);
};

export default LinkPills;
