import React from 'react';

const ThumbsUp = () => {
	const style = {
		display: 'inlineBlock',
		width: '1.130859375em',
		height: '1em',
		strokeWidth: '0',
		stroke: 'currentColor',
		fill: 'currentColor',
	};

	return (
		<svg viewBox="0 0 36 36" style={style}>
			<path d="M9.739 12.058c1.025 0 1.855 0.831 1.855 1.855v18.087c0 1.025-0.831 1.855-1.855 1.855h-8.348c-0.861 0-1.687-0.342-2.295-0.951s-0.951-1.435-0.951-2.296v-15.304c0-0.861 0.342-1.687 0.951-2.296s1.435-0.951 2.296-0.951h8.348zM7.884 15.768h-6.029v14.377h6.029v-14.377z" />
			<path d="M16.696-1.855c1.968 0 3.855 0.782 5.247 2.173s2.173 3.279 2.173 5.247v2.319h9.276c0.526 0 1.048 0.090 1.542 0.264l0.365 0.147c0.6 0.271 1.135 0.666 1.57 1.16s0.76 1.074 0.954 1.703c0.194 0.629 0.251 1.291 0.169 1.942l-2.087 16.697c-0.141 1.12-0.686 2.151-1.533 2.899s-1.938 1.16-3.067 1.16h-21.565c-1.025 0-1.855-0.831-1.855-1.855s0.831-1.855 1.855-1.855h21.565c0.225 0 0.443-0.082 0.613-0.232s0.279-0.356 0.307-0.578l2.087-16.697c0.016-0.131 0.005-0.263-0.034-0.389s-0.104-0.242-0.191-0.341c-0.087-0.099-0.194-0.178-0.314-0.232s-0.25-0.082-0.381-0.082h-11.13c-1.025 0-1.855-0.831-1.855-1.855v-4.174c0-0.984-0.391-1.928-1.087-2.623-0.387-0.387-0.85-0.679-1.354-0.863l-0.203-0.065-6.364 12.728c-0.425 0.851-1.417 1.232-2.29 0.915l-0.199-0.086c-0.916-0.458-1.288-1.572-0.83-2.489l6.957-13.913c0.314-0.628 0.957-1.025 1.659-1.025z" />
		</svg>
	);
};

export default ThumbsUp;
