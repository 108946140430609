import React, { useContext } from 'react';
import MarkdownAnchor from '../MarkdownAnchor';
import { AuthContext } from '../../context/auth-context';
import { PRACTITIONER_STATUS_MESSAGE } from '../../data/constants';

const PractitionerStatusMessage = props => {
  const { variant = 'long', ...rest } = props;
  const { state: authState } = useContext(AuthContext);
  const { practitioner } = authState;
  const status = practitioner.status || '';
  const MESSAGE = PRACTITIONER_STATUS_MESSAGE[status.toUpperCase()];

  if (!MESSAGE) {
    return null;
  }

  const text = MESSAGE[variant.toUpperCase()] || MESSAGE.LONG;

  return <MarkdownAnchor text={text} {...rest} />;
};

export default PractitionerStatusMessage;
