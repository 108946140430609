import React, { useState } from 'react';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import Spinner from '../Spinner';
// import { HiOutlineArrowRight } from 'react-icons/hi';

import SafeAnchor from '../SafeAnchor';

import { isExternalUrl } from '../../utils';

/*
- href prop wll create a regular a-tag but still with button classes
- disabled prop will add 'tabindex' of -1 and 'aria-disabled' of 'true' for accessibility
- variant - prop will add a modifier to classname
*/

const ns = `button`;

const Button = React.forwardRef(
	(
		{
			active,
			className,
			variant,
			type,
			as,
			to,
			href,
			isLoading,
			style,
			backgroundColor = undefined,
			color = undefined,
			hoverBackgroundColor = undefined,
			hoverFontColor = undefined,
			// withArrow,
			...props
		},
		ref
	) => {
		// define button classes
		const rootClassnames = classNames(
			className,
			`${ ns }`,
			variant && `${ ns }--${ variant }`,
			// withArrow && `${ ns }--with-arrow`,
			active && 'active'
		);

		const [hover, setHover] = useState(false);

		// if (withArrow) {
		// 	// eslint-disable-next-line
		// 	props.children = (
		// 		<div>
		// 			<span className={`arrow`}>
		// 				<HiOutlineArrowRight size={15} />
		// 			</span>
		// 			<span>{props.children}</span>
		// 		</div>
		// 	);
		// }

		// setting the title on all buttons
		// for the hover state animation

		// eslint-disable-next-line no-param-reassign
		props.title = props.title || props.children;

		if (href || isExternalUrl(to)) {
			return (
				<SafeAnchor
					{...props}
					href={href || to}
					as={as}
					ref={ref}
					target="_blank"
					className={classNames(rootClassnames, props.disabled && 'disabled')}
					style={{
						backgroundColor: hover ? hoverBackgroundColor : backgroundColor,
						color: hover ? hoverFontColor : color,
						...style,
					}}
					onMouseEnter={() => { setHover(true); }}
					onMouseLeave={() => { setHover(false); }}
				/>
			);
		}

		if (ref) {
			props.ref = ref; // eslint-disable-line
		}

		if (!as) {
			props.type = type; // eslint-disable-line
		}

		if (to) {
			return (
				<GatsbyLink
					{...props}
					to={to || '/'}
					ref={ref}
					style={{
						backgroundColor: hover ? hoverBackgroundColor : backgroundColor,
						color: hover ? hoverFontColor : color,
						...style,
					}}
					className={rootClassnames}
					onMouseEnter={() => { setHover(true); }}
					onMouseLeave={() => { setHover(false); }}
				/>
			);
		}

		const Component = as || 'button';
		const { children, ...buttonProps } = props;
    const contentMarkup = isLoading ? (
      <Spinner style={{ display: 'block', margin: '0 auto' }} />
    ) : (
      children
    );
		return (
			<Component
				{...buttonProps}
				className={rootClassnames}
				style={{
					backgroundColor: hover ? hoverBackgroundColor : backgroundColor,
					color: hover ? hoverFontColor : color,
					...style,
				}}
				onMouseEnter={() => { setHover(true); }}
				onMouseLeave={() => { setHover(false); }}
			>
				{contentMarkup}
			</Component>
		);
		/* eslint-enable */
	}
);

export default Button;
