import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({
	description,
	lang,
	meta,
	title,
	image,
	page,
	link,
	canonicalPathName,
}) => {
	const { site, allShopifyCollection, wp } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteTitle
						siteDescription
						siteUrl
					}
				}
				allShopifyCollection {
					nodes {
						metafield(key: "no_index", namespace: "custom") {
							key
							value
						}
						handle
					}
				}
				wp {
					generalSettings {
						title
						description
					}
					globalSettings {
						globalSettings {
							siteDescription
						}
					}
				}
			}
		`
	);

	const siteTitle = wp.generalSettings.title || site.siteMetadata.siteTitle;
	const siteTagline = wp.generalSettings.description;
	const { siteDescription } = wp.globalSettings.globalSettings;
	const defaultTitle = siteTagline ? `${ siteTitle } | ${ siteTagline } ` : siteTitle;

	const metaTitle = title || siteTitle;
	const metaDescription = description ? description.replace(/(<([^>]+)>)/ig, '') : siteDescription || site.siteMetadata.siteDescription;
	const metaImage = image;
	const metaUrl = site.siteMetadata.siteUrl;

	let canonical;
	if (link) {
		canonical = new URL(link);
		canonical.hostname = new URL(metaUrl).hostname;
		canonical.search = '';

		if (canonicalPathName) {
			canonical.pathname = canonicalPathName;
		}
	}

	const collection = typeof window !== 'undefined' ? allShopifyCollection.nodes.find(col => col.handle === window.location.pathname.replace(/^\/+|\/+$/g, '')) : null;

	const noIndexCollection = collection !== undefined && collection?.metafield && collection.metafield.value === 'true';

	const metaArray = [
		{
			name: `description`,
			content: metaDescription,
		},
		{
			property: `og:url`,
			content: metaUrl,
		},
		{
			property: `og:title`,
			content: metaTitle,
		},
		{
			property: `og:description`,
			content: metaDescription,
		},
		{
			property: `og:image`,
			content: metaImage,
		},
		{
			property: `og:type`,
			content: `website`,
		},
		{
			property: `twitter:card`,
			content: `summary`,
		},
		{
			property: `twitter:title`,
			content: metaTitle,
		},
		{
			property: `twitter:description`,
			content: metaDescription,
		},
		{
			property: `twitter:image`,
			content: metaImage,
		},
	];

	// Add the robots property only if noIndexCollection is true
	if (noIndexCollection) {
		metaArray.push({
			property: `robots`,
			content: 'noindex, nofollow',
		});
	}
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			defaultTitle={defaultTitle}
			titleTemplate={`%s | ${ siteTitle }`}
			bodyAttributes={
				{
					'data-page': page,
				}
			}
			meta={metaArray.concat(meta)}
		>
			{link && canonical && <link rel="canonical" href={canonical} />}
		</Helmet>
	);
};

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

export default SEO;
