import React from 'react';

import Accessibility from '../icons/Accessibility';
import Account from '../icons/Account';
import CarrotDown from '../icons/CarrotDown';
import CarrotLeft from '../icons/CarrotLeft';
import CarrotRight from '../icons/CarrotRight';
import Cart from '../icons/Cart';
import Checkbox from '../icons/Checkbox';
import Cross from '../icons/Cross';
import ExternalLink from '../icons/ExternalLink';
import Eye from '../icons/Eye';
import Facebook from '../icons/Facebook';
import Instagram from '../icons/Instagram';
import LinkedIn from '../icons/LinkedIn';
import Location from '../icons/Location';
import Logo from '../icons/Logo';
import Menu from '../icons/Menu';
import PDF from '../icons/PDF';
import Pinterest from '../icons/Pinterest';
import Search from '../icons/Search';
import Star from '../icons/Star';
import Sunwave from '../icons/Sunwave';
import ThumbsDown from '../icons/ThumbsDown';
import ThumbsUp from '../icons/ThumbsUp';
import Twitter from '../icons/Twitter';
import Upload from '../icons/Upload';
import X from '../icons/X';
import XL from '../icons/XL';

const iconComponents = {
	'carrot-down': CarrotDown,
	'carrot-left': CarrotLeft,
	'carrot-right': CarrotRight,
	'external-link': ExternalLink,
	accessibility: Accessibility,
	account: Account,
	cart: Cart,
	checkbox: Checkbox,
	cross: Cross,
	eye: Eye,
	facebook: Facebook,
	instagram: Instagram,
	linkedin: LinkedIn,
	location: Location,
	logo: Logo,
	menu: Menu,
	pdf: PDF,
	pinterest: Pinterest,
	search: Search,
	star: Star,
	sunwave: Sunwave,
	thumbsDown: ThumbsDown,
	thumbsUp: ThumbsUp,
	twitter: Twitter,
	upload: Upload,
	x: X,
	xl: XL,
};

const renderIcon = (icon) => {
	const IconComponent = iconComponents[icon];
	return IconComponent ? <IconComponent /> : null;
};

export default renderIcon;
