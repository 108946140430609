import React from 'react';
import classNames from 'classnames';

const ns = `image`;

const Image = props => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const {
		altText,
		title,
		sourceUrl,
		mainImageMobile,
		tabletImage,
		laptopImage,
		desktopImage,
		widescreenImage,
		dataMedia,
		onLoad,
	} = props;

	let tabletSet = null;
	let laptopSet = null;
	let desktopSet = null;
	let widescreenSet = null;

	const getSrc = src => {
		if (typeof src === 'string') {
			return src;
		}
		return src?.sourceUrl;
	};

	// if no mainImageMobile return nothing
	if (!getSrc(mainImageMobile) && !sourceUrl) return null;

	if (tabletImage && tabletImage.sourceUrl) {
		tabletSet = <source media={`(min-width: 768px)`} srcSet={getSrc(tabletImage || laptopImage || desktopImage || sourceUrl)} />;
	}

	if (laptopImage && laptopImage.sourceUrl) {
		laptopSet = <source media={`(min-width: 992px)`} srcSet={getSrc(laptopImage || desktopImage || sourceUrl)} />;
	}

	if (desktopImage && desktopImage.sourceUrl) {
		desktopSet = <source media={`(min-width: 1200px)`} srcSet={getSrc(desktopImage || sourceUrl)} />;
	}

	if (widescreenImage && widescreenImage.sourceUrl) {
		widescreenSet = <source media={`(min-width: 1400px)`} srcSet={getSrc(widescreenImage || sourceUrl)} />;
	}

	return (
		<picture>
			{widescreenSet}
			{desktopSet}
			{laptopSet}
			{tabletSet}
			<img
				className={rootClassnames}
				alt={altText || title || mainImageMobile?.altText || mainImageMobile?.title}
				src={getSrc(mainImageMobile) || getSrc(sourceUrl)}
				data-media={dataMedia}
				loading={'lazy'}
				draggable={`false`}
				onLoad={onLoad}
			/>
		</picture>
	);
};

export default Image;
