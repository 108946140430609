import React from 'react';

const Account = () => {
	return (
		<svg width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
			<path d={'M12.5 1C8.35357 1 5 4.35357 5 8.5C5 14.125 12.5 22.4286 12.5 22.4286C12.5 22.4286 20 14.125 20 8.5C20 4.35357 16.6464 1 12.5 1ZM12.5 11.1786C11.0214 11.1786 9.82143 9.97857 9.82143 8.5C9.82143 7.02143 11.0214 5.82143 12.5 5.82143C13.9786 5.82143 15.1786 7.02143 15.1786 8.5C15.1786 9.97857 13.9786 11.1786 12.5 11.1786Z'} fill={'currentColor'} />
		</svg>
	);
};

export default Account;
