/* eslint-disable */
import debounce from "lodash.debounce"

const flowSpeed = 1.2
const defaultWaveHeight = 35
const defaultTopColor = '#fff'
const defaultBottomColor = '#fff'

class SineWave {
  constructor (containerId, canvasId, topColor = defaultTopColor, bottomColor = defaultBottomColor, waveHeight = defaultWaveHeight) {
    this.container = document.getElementById(containerId)
    this.canvas = document.getElementById(canvasId)
    this.context = this.canvas.getContext('2d')
    this.topColor = topColor
    this.bottomColor = bottomColor
    this.waveHeight = waveHeight
    this.drawObj = {t: 0, seconds: 0}

    // Bind functions
    this.draw = this.draw.bind(this)
    this.drawSine = this.drawSine.bind(this)
    this.drawWave = this.drawWave.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }

  init () {
    // console.log('init wave', this.container)

    // this.time = window.scrollY || document.documentElement.scrollTop
    this.time = 700
    this.drawObj.seconds = this.drawObj.t = 0

    window.addEventListener('resize', debounce(this.handleResize, 200))
    // Uncomment this line to enable wave animation on scroll
    // window.addEventListener('scroll', this.draw)

    this.handleResize()
    this.draw()
  }

  handleResize () {
    this.canvas.width = this.container.offsetWidth
    this.canvas.height = this.container.offsetHeight / 0.4
    this.unit = this.canvas.width / 20
    this.width = this.canvas.width
    this.height = this.canvas.height
    this.xAxis = Math.floor(this.height / 2)
    this.yAxis = 0

    this.gradient = this.context.createLinearGradient(this.canvas.width / 2, this.canvas.height, this.canvas.width / 2, 0)
    this.gradient.addColorStop(0.1, this.bottomColor)
    this.gradient.addColorStop(1, this.topColor)
    this.draw()
  }

  draw () {
    let newTime = window.scrollY || document.documentElement.scrollTop
    let dT = newTime - this.time
    this.time = newTime

    this.context.clearRect(0, 0, this.width, this.height)

    this.drawWave('#ffffff', 1, 3, 0)

    this.drawObj.seconds = this.drawObj.seconds + dT / 1000 * flowSpeed
    this.drawObj.t = this.drawObj.seconds * Math.PI
  }

  drawWave (fillcolor, alpha, zoom, delay) {
    this.context.fillStyle = this.gradient
    this.context.globalAlpha = alpha

    this.context.beginPath()
    this.drawSine(this.drawObj.t / 0.5, zoom, delay)
    this.context.lineTo(this.width + 10, this.height)
    this.context.lineTo(0, this.height)
    this.context.closePath()
    this.context.fill()
  }

  drawSine (t, zoom, delay) {
    let x = t
    let y = Math.sin(x) / zoom
    let scaleX = (Math.sin(x / 3) + 1) / 2 * (1 - 0.7) + 0.7
    let offset = Math.sin(x / 4)

    this.context.moveTo(this.yAxis, this.unit * y + this.xAxis)

    Math.sin(this.time)

    for (let i = this.yAxis; i <= this.width + 10; i += 10) {
      x = t + (-this.yAxis + i) / this.unit / zoom * scaleX
      y = Math.sin(x - delay) / this.container.offsetHeight * this.waveHeight * offset
      this.context.lineTo(i, this.unit * y + this.xAxis)
    }
  }
}

export default SineWave
