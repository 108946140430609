import { noop } from '.';

const klaviyoTarget = {
  account: noop,
  cookieDomain: noop,
  identify: noop,
  isIdentified: noop,
  openForm: noop,
  push: noop,
  track: noop,
  trackViewedItem: noop,
};

const klaviyoHandler = {
  get (target, property) {
    const targetValue = target[property];

    if (typeof window !== 'undefined') {
      const { klaviyo = {} } = window;
      return klaviyo[property] ?? targetValue;
    }

    return targetValue;
  }
};

const klaviyoProxy = new Proxy(klaviyoTarget, klaviyoHandler);

export function identifyKlaviyoProfile ({ email }) {
  if (!email) return false;

  const { klaviyo } = window || {};

  //	Make sure klaviyo is initialized at this time
  if (!(klaviyo && typeof klaviyo.push === 'function')) {
    window.console.log('There is no Klaviyo framework initialized.');
    return false;
  }

  klaviyo.identify({
    $email: email,
  });

  window.console.log('Klaviyo identified user.');

  return true;
}

export default klaviyoProxy;
