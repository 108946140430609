/* eslint-disable */

import { useEffect, useState, useMemo, useRef } from 'react';
import queryString from 'query-string';
import { useQuery, useClient, gql } from 'urql';
import { ProductsQuery, createQuery } from './search';
import { trackViewedProduct } from './tracking';

function makeQueryStringValue(allItems, selectedItems) {
	if (allItems.length === selectedItems.length) {
		return [];
	}
	return selectedItems;
}

export function useProductSearch(
	filters,
	{ allTags, allProductTypes, allVendors },
	sortKey,
	pause = false,
	count = 20,
	initialData = [],
	initialFilters
) {
	const [query, setQuery] = useState(createQuery(filters));
	const [cursors, setCursors] = useState({
		before: null,
		after: null,
	});
	const [initialRender, setInitialRender] = useState(true);
	const {
		term, tags, productTypes, minPrice, maxPrice, vendors,
	} = filters;

	// Relevance is non-deterministic if there is no query, so we default to "title" instead
	const initialSortKey = filters.term ? 'RELEVANCE' : 'TITLE';

	// only fetch after the filters have changed
	const shouldPause = useMemo(() => { return (query === createQuery(initialFilters)) || pause; }, [query, pause, initialFilters]);

	const [result] = useQuery({
		query: ProductsQuery,
		variables: {
			query,
			sortKey: sortKey || initialSortKey,
			first: !cursors.before ? count : null,
			last: cursors.before ? count : null,
			after: cursors.after,
			before: cursors.before,
		},
		pause: shouldPause,
	});

	useEffect(() => {
		const qs = queryString.stringify({
			// Don't show if falsy
			q: term || undefined,
			x: maxPrice || undefined,
			n: minPrice || undefined,
			// Don't show if sort order is default
			s: sortKey === initialSortKey ? undefined : sortKey,
			// Don't show if all values are selected
			p: makeQueryStringValue(allProductTypes, productTypes),
			v: makeQueryStringValue(allVendors, vendors),
			t: makeQueryStringValue(allTags, tags),
			c: cursors.after || undefined,
		});

		const url = new URL(window.location.href);
		url.search = qs;
		url.hash = '';
		window.history.replaceState({}, null, url.toString());
		setQuery(createQuery(filters));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, cursors, sortKey]);

	const fetchPreviousPage = () => {
		// when we go back we want all products before the first one of our array
		const previousCursor = result.data.products.edges[0].cursor;
		setCursors({
			before: previousCursor,
			after: null,
		});
	};
	const fetchNextPage = () => {
		// when we go forward we want all products after the first one of our array
		const prods = result.data.products;
		const nextCursor = prods.edges[prods.edges.length - 1].cursor;
		setCursors({
			before: null,
			after: nextCursor,
		});
	};

	const filterCount = (filters.tags.length === allTags.length ? 0 : filters.tags.length)
    + (filters.productTypes.length === allProductTypes.length
    	? 0
    	: filters.productTypes.length)
    + (filters.vendors.length === allVendors.length
    	? 0
    	: filters.vendors.length)
    + (filters.minPrice ? 1 : 0)
    + (filters.maxPrice ? 1 : 0);

	let hasPreviousPage;
	let hasNextPage;

	const products = useMemo(() => {
		if (query === createQuery(initialFilters)) {
			return initialData;
		}
		if (result.data && initialRender) setInitialRender(false);
		return result.data?.products?.edges || [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query, result.data, initialData, initialFilters]);

	if (result && result.data) {
		hasPreviousPage = result.data.products.pageInfo.hasPreviousPage;
		hasNextPage = result.data.products.pageInfo.hasNextPage;
	}

	const isFetching = !initialRender && result.fetching;

	return {
		data: result.data,
		isFetching,
		hasPreviousPage,
		hasNextPage,
		products,
		filterCount,
		fetchNextPage,
		fetchPreviousPage,
	};
}

function arrayify(value) {
	if (!value) {
		return [];
	}
	if (!Array.isArray(value)) {
		return [value];
	}
	return value;
}

/**
 * Extracts default search values from the query string
 * @param {string} query
 */
export function getValuesFromQueryString(query) {
	const {
		q: term,
		s: sortKey,
		x: maxPrice,
		n: minPrice,
		p,
		t,
		v,
	} = queryString.parse(query);
	return {
		term,
		sortKey,
		maxPrice,
		minPrice,
		productTypes: arrayify(p),
		tags: arrayify(t),
		vendors: arrayify(v),
	};
}

export const useWindowEventListener = (windowEvent, handlerCallback, useCapture = false) => {
	useEffect(() => {
		const isBrowser = typeof window !== 'undefined';

		if (isBrowser) {
			window.addEventListener(windowEvent, handlerCallback, useCapture);
		}

		return () => {
			window.removeEventListener(windowEvent, handlerCallback, useCapture);
		};
	}, [windowEvent, handlerCallback, useCapture]);
};


export const useIntersectionObserver = (intersectHandler, config) => {
	const observedRef = useRef();
	useEffect(() => {
		let observer = null;
		const ioRef = observedRef.current;
		if (ioRef) {
			/**
			 * Callback when entry is intersecting.
			 */
			const ioCb = entries => {
				entries.forEach(entry => {
					(typeof intersectHandler === 'function' && intersectHandler(entry));
				});
			};

			/**
			 * Create observer.
			 */
			observer = new IntersectionObserver(ioCb, config);

			/**
			 * Register observer.
			 */
			observer.observe(ioRef);
		}

		return () => {
			if (observer && ioRef) {
				observer.unobserve(ioRef);
			}
		};
	}, [intersectHandler, config]);

	return observedRef;
}

const RelatedProductsQuery = gql`
	query recommended($productId: ID!) {
		productRecommendations(productId: $productId) {
			id
			shopifyId: id
      title
      handle
      tags
      createdAt
      vendor
      productType
      priceRangeV2: priceRange {
        minVariantPrice {
          currencyCode
          amount
        }
        maxVariantPrice {
          currencyCode
          amount
        }
      }
      images(first: 20) {
        edges {
          node {
            altText
            originalSrc
            src
          }
        }
      }
      variants(first: 20) {
        edges {
          node {
            availableForSale
            title
            price {
							currencyCode
							amount
						}
						compareAtPrice {
							currencyCode
							amount
						}
            selectedOptions {
              name
              value
            }
            id
						shopifyId: id
            sku
            image {
              altText
              originalSrc
            }
          }
        }
      }
      options {
        name
        values
        id
				shopifyId: id
      }
      shortDescription: metafield(key: "short_description", namespace: "product_information") {
        value
      }
      collections(first: 99) {
        edges {
            node {
            title
          }
        }
      }
		}
	}
`;

export const useRecommendedProducts = productIds => {
	const client = useClient();
	const [isLoading, setLoading] = useState(true);
	const [result, setResult] = useState([]);

	useEffect(() => {
		Promise.all(
			productIds.map(productId => {
				return client
					.query(RelatedProductsQuery, {
						productId,
					})
					.toPromise();
			})
		).then(res => {
			const products = res
				.map(r => {
					return r.data.productRecommendations;
				})
				.flat(1);
			const uniqueProducts = [];
			products.forEach(product => {
				if (!uniqueProducts.find(p => { return p.id === product.id; })) {
					uniqueProducts.push(product);
				}
			});
			// shuffle them and look for products that were recommended more than once for the cart
			const sortedProducts = uniqueProducts.sort(() => { return 0.5 - Math.random(); }).filter(p => { return !!p; }).map(p => {
				return {
					...p,
					count: products.filter(p2 => { return p2.id === p.id; }).length,
				};
			}).filter(p => { return !productIds.includes(p.id); })
				.sort((a, b) => { return b.count - a.count; })
				.slice(0, 7);
			setResult(sortedProducts);
			setLoading(false);
		});
	}, []);

	return [
		isLoading,
		result,
	];
};

export const useIsMobile = () => {
	const [isMobile, setMobile] = useState(null);
	useEffect(() => {
		const handleResize = () => {
			setMobile(window.innerWidth < 992);
		};
		handleResize(); // Set initial value
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return isMobile;
};