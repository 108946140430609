import ExtendedError from './extended-error';
import { sprintf, get } from '..';

export class Auth0Error extends ExtendedError {
  constructor(props) {
    const description = props.description || {};
    const rules = description.rules || [];
    const messagePath = ['original', 'response', 'body', 'message'];
    const message = get(props, messagePath, description);

    const items = rules.reduce((acc, cur) => {
      const { format, message: msg, ...rest } = cur;
      const msgFormatted = sprintf(msg, format);
      const error = { ...rest, message: msgFormatted };
      acc.push(error);
      return acc;
    }, []);

    const superProps = {
      ...props,
      message,
      ...(items.length && { items }),
    };

    super(superProps);
  }
}

export default Auth0Error;
