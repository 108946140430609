import React from 'react';
import EducationHubLayout from '../EducationHubLayout';

const Layout = ({
	children,
	location,
	pageContext,
	data,
}) => {
	const layout = pageContext?.layout || data?.page?.slug;

	switch (layout) {
		case 'flexible-education-hub':
		case 'education-hub':
			return (
				<EducationHubLayout location={location} data={data}>
					{children}
				</EducationHubLayout>
			);
		default:
			return children;
	}
};

export default Layout;
