import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { identifyKlaviyoProfile } from '../../utils/klaviyo';

const ns = 'newsletter';
const KLAVIYO_LIST_ENDPOINT = 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe';

const validationSchema = yup.object({
	email: yup.string().email('Invalid email address').required('Email is required'),
	g: yup.string().required(),
}).required();

const Newsletter = ({
	newsletterListId
}) => {
	const rootClassNames = classNames({
		[`${ ns }`]: true,
	});

	const [isClient, setIsClient] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const {
		register, handleSubmit,
		formState: { errors: formErrors, isSubmitting },
	} = useForm({
		defaultValues: { g: newsletterListId },
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = async data => {
		identifyKlaviyoProfile({ email: data?.email });

		try {
			const payload = new URLSearchParams(data);

			const response = await fetch(KLAVIYO_LIST_ENDPOINT, {
				body: payload,
				method: 'POST',
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
					'cache-control': 'no-cache',
				},
			});

			const json = await response.json();

			if (json.success) {
				setIsSuccess(true);
			} else {
				setIsError(true);
			}
		} catch (err) {
			// eslint-disable-next-line
			console.error('Failed to subscribe newsletter: ', err);
			setIsError(true);
		}
	};

	useEffect(() => {
		setIsClient(true);
	}, []);

	if (!isClient) return null;

	return (
		<div className={rootClassNames}>
			{isError && (
				<div className={`${ ns }__submit-error`}>
					<p>An error occurred when submitting. Please try again later.</p>
				</div>
			)}

			{isSuccess && (
				<div className={`${ ns }__submit-success`}>
					<h3>Thanks for subscribing!</h3>
					<p>We&apos;ll be sending you a welcome email shortly.</p>
				</div>
			)}

			{!isSuccess && !isError && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<input {...register('g')} id="g" type="hidden" />
					<input
						{...register('email')}
						type="text"
						placeholder="Enter your email"
						disabled={isSubmitting}
					/>
					<button disabled={isSubmitting} type="submit">Submit</button>
				</form>
			)}

			{formErrors.email?.message && (
				<div className={`${ ns }__form-error`}>
					<p>{formErrors.email.message}</p>
				</div>
			)}
		</div>
	);
};

export default Newsletter;
