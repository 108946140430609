class ExtendedError extends Error {
  constructor(props) {
    let displayMessage;
    const { message, description, errors = [], ...rest } = props;

    if (typeof message === 'string') {
      displayMessage = message;
    } else if (typeof description === 'string') {
      displayMessage = description;
    }

    const error = displayMessage
      ? {
        ...props,
        message: displayMessage,
        }
      : {
          ...rest,
          ...errors[0],
          errors: errors.slice(1),
        };

    super(error.message);

    if (error.errors?.length) {
      this.errors = error.errors;
    }

    Object.keys(rest).forEach(key => {
      if (!Object.hasOwn(this, key)) {
        this[key] = rest[key];
      }
    });
  }

  toJSON() {
    const baseJSON = { message: this.message };
    return Object.entries(this).reduce((acc, cur) => {
      const [key, value] = cur;
      acc[key] = value;
      return acc;
    }, baseJSON);
  }

  get json() {
    return this.toJSON();
  }
}

export default ExtendedError;
