import React from 'react';

const CarrotRight = () => {
	return (
		<svg width={'8'} height={'13'} viewBox={'0 0 8 13'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
			<path d={'M1.22178 1.19692C0.824442 1.58949 0.824093 2.23095 1.22101 2.62395L4.05913 5.43414C4.65192 6.0211 4.65192 6.97895 4.05912 7.56591L1.22101 10.3761C0.824093 10.7691 0.824443 11.4106 1.22179 11.8031C1.6127 12.1894 2.24154 12.1894 2.63245 11.8031L6.91997 7.56706C7.51432 6.97985 7.51432 6.0202 6.91997 5.43298L2.63245 1.19692C2.24154 0.810695 1.6127 0.810694 1.22178 1.19692Z'} fill={'currentColor'} />
		</svg>

	);
};

export default CarrotRight;
