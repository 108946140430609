import React from 'react';

const ThumbsDown = () => {
	const style = {
		display: 'inlineBlock',
		width: '1.130859375em',
		height: '1em',
		strokeWidth: '0',
		stroke: 'currentColor',
		fill: 'currentColor',
	};

	return (
		<svg viewBox="0 0 36 36" style={style}>
			<path d="M9.739-1.855c1.025 0 1.855 0.831 1.855 1.855v18.087c0 1.025-0.831 1.855-1.855 1.855h-8.348c-0.861 0-1.687-0.342-2.295-0.951s-0.951-1.435-0.951-2.296v-15.304c0-0.861 0.342-1.687 0.951-2.296s1.435-0.951 2.296-0.951h8.348zM7.884 1.855h-6.029v14.377h6.029v-14.377z" />
			<path d="M31.304-1.855c1.13 0 2.22 0.413 3.067 1.16s1.392 1.778 1.533 2.9l2.087 16.694c0.082 0.653 0.025 1.316-0.169 1.944s-0.519 1.209-0.954 1.703c-0.435 0.493-0.97 0.889-1.57 1.159s-1.25 0.411-1.908 0.411h-9.275v2.319c0 1.827-0.674 3.585-1.885 4.941l-0.289 0.306c-1.392 1.392-3.279 2.173-5.247 2.173-0.703 0-1.345-0.397-1.659-1.025l-6.957-13.913c-0.458-0.916-0.087-2.031 0.83-2.489s2.031-0.087 2.489 0.83l6.364 12.726 0.203-0.063c0.403-0.147 0.78-0.363 1.113-0.642l0.241-0.221c0.696-0.696 1.087-1.64 1.087-2.623v-4.174c0-1.025 0.831-1.855 1.855-1.855h11.13c0.132-0 0.262-0.028 0.382-0.082s0.227-0.133 0.314-0.232c0.087-0.099 0.152-0.215 0.191-0.341s0.050-0.258 0.033-0.39l-2.087-16.694c-0.028-0.224-0.137-0.43-0.307-0.579s-0.387-0.232-0.613-0.232h-21.565c-1.025 0-1.855-0.831-1.855-1.855s0.831-1.855 1.855-1.855h21.565z" />
		</svg>
	);
};

export default ThumbsDown;
