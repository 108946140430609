import { ExtendedError } from '../error';
import { get } from '..';

class Nordic {
  constructor(idToken) {
    if (!idToken) {
      throw new Error('Missing id token');
    }

    this.idToken = idToken;
  }

  async callAPI(options = {}) {
    const { endpoint, payload, method = 'GET', headers = {}, ...rest } = options;
    const bearerToken = `Bearer ${this.idToken}`;
    const fetchOptions = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-Gatsby-Authorization': bearerToken,
        ...headers,
      },
      body: JSON.stringify(payload),
      ...rest,
    };

    const response = await fetch(endpoint, fetchOptions);
    const { data, error } = await response.json();

    if (!response.ok || error) {
      throw new ExtendedError({
        response,
        code: get(error, 'code') || 'gatsby_http_error',
        name: get(error, 'name') || 'gatsby_http_error',
        message: get(error, 'message') || 'Failed to fetch Nordic API',
        status: response.status,
        statusText: response.statusText,
      });
    }

    return data;
  }

  /**
   * @param {string} [redirectURL]
   * @returns Promise<object>
   */
  async getMultipassURL(redirectURL) {
    const endpoint = '/api/shopify_multipass';
    const headers = {
      'X-Gatsby-Multipass-Type': 'url',
      ...(redirectURL && { 'X-Gatsby-Shopify-Redirect': redirectURL }),
    };

    const data = await this.callAPI({ endpoint, headers });
    return data || {};
  }

  /**
   * @param {string} [redirectURL]
   * @returns Promise<object>
   */
  async getMultipassToken(redirectURL) {
    const endpoint = '/api/shopify_multipass';
    const headers = {
      'X-Gatsby-Multipass-Type': 'token',
      ...(redirectURL && { 'X-Gatsby-Shopify-Redirect': redirectURL }),
    };

    const data = await this.callAPI({ endpoint, headers });
    return data || {};
  }
}

export default Nordic;
