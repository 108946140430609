import React from 'react';
import classNames from 'classnames';

const ns = `spinner`;

const Spinner = props => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<span className={rootClassnames} {...props} />
	);
};

export default Spinner;
