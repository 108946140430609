import React from 'react';

const ExternalLink = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M14.75 5.75L14.75 1.25M14.75 1.25H10.25M14.75 1.25L8.75 7.25M6.5 2.75H4.85C3.58988 2.75 2.95982 2.75 2.47852 2.99524C2.05516 3.21095 1.71095 3.55516 1.49524 3.97852C1.25 4.45982 1.25 5.08988 1.25 6.35V11.15C1.25 12.4101 1.25 13.0402 1.49524 13.5215C1.71095 13.9448 2.05516 14.289 2.47852 14.5048C2.95982 14.75 3.58988 14.75 4.85 14.75H9.65C10.9101 14.75 11.5402 14.75 12.0215 14.5048C12.4448 14.289 12.789 13.9448 13.0048 13.5215C13.25 13.0402 13.25 12.4101 13.25 11.15V9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default ExternalLink;
