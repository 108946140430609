import React from 'react';
import Link from '../Link';

// Parses markdown text containing a single link
// example: Your application is incomplete. Continue your progress [here](/account/register)
const MarkdownAnchor = React.forwardRef((props, ref) => {
  const { linkProps, component, text = '', ...rest } = props;
  const regex = /(.+)?\[(.+)\]\((.+)\)(.+)?|.+/g;
  const matches = [...text.matchAll(regex)];

  if (!matches.length) {
    return text;
  }

  const Wrapper = component || 'span';

  const textMarkup = matches.map(match => {
    const { index } = match;
    const [input, head, anchor, href, tail] = match;

    if (!anchor || !href) {
      return (
        <Wrapper key={index} ref={ref} {...rest}>
          {input}
        </Wrapper>
      );
    }

    return (
      <Wrapper key={index} ref={ref} {...rest}>
        {head}
        <Link to={href} {...linkProps}>
          {anchor}
        </Link>
        {tail}
      </Wrapper>
    );
  });

  return textMarkup;
});

export default MarkdownAnchor;
