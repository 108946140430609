/**
 * Register for a webinar using the ON24 API
 * @param {object} user
 * @param {string} user.city
 * @param {string} user.company
 * @param {string} user.email
 * @param {string} user.eventId
 * @param {string} user.firstName
 * @param {string} user.keepMeUpdated
 * @param {string} user.lastName
 * @param {string} user.phone
 * @param {string} user.practiceType
 * @param {string} user.practiceTypeOther
 * @param {string} user.referralSource
 * @param {string} user.state
 * @param {string} user.zip
 * @returns Promise<object>
 */
export const register = async (user) => {
	const endpoint = '/api/on24_register';

	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(user),
	};

	const response = await fetch(endpoint, options);
	const json = await response.json();
	const { error } = json;

	if (error) {
		const errorMessage = error.message || 'Failed to register for on24 webinar';
		throw new Error(errorMessage);
	}

	return json;
};
