import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import renderIcon from '../../utils/render-icon';

// Setup app element for react-modal
ReactModal.setAppElement('#___gatsby');

const ns = `site-modal`;

const Modal = ({
	children,
	modifier,
	contentLabel = '',
	isOpen = false,
	onRequestClose,
	shouldHideCloseButton = false,
	...reactModalProps
}) => {
	const [openState, setIsOpenState] = useState(isOpen);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ modifier }`]: modifier,
	});

	const handleCloseModal = () => {
		if (onRequestClose && typeof onRequestClose === 'function') {
			onRequestClose();
		} else {
			setIsOpenState(false);
		}
	};

	useEffect(() => {
		setIsOpenState(isOpen);
	}, [isOpen]);

	return (
		<ReactModal
			contentLabel={contentLabel}
			className={rootClassnames}
			bodyOpenClassName={`${ ns }--open`}
			overlayClassName={`${ ns }__overlay`}
			isOpen={openState}
			onRequestClose={handleCloseModal}
			closeTimeoutMS={400}
			{...reactModalProps}
		>
			<div className={`${ ns }__content-inner`}>
				{!shouldHideCloseButton && (
					<button
						className={`${ ns }__close`}
						onClick={handleCloseModal}
					>
						{renderIcon('x')}
						<span className={`visually-hidden`}>Close</span>
					</button>
				)}
				{children}
			</div>
		</ReactModal>
	);
};

export default Modal;
