import React from 'react';
import classNames from 'classnames';
import Spinner from './Spinner';

const ns = `spinner-page-wrapper`;

const PageSpinner = props => {
	const { containerProps, ...rest } = props;
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames} {...containerProps}>
			<Spinner {...rest} />
		</div>
	);
};

export default PageSpinner;
