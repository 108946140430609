import * as React from 'react';
import SEO from './src/components/SEO';
import { StoreProvider } from './src/context/store-context';
import { AuthProvider } from './src/context/auth-context';
import { TrackingProvider } from './src/context/tracking-context';

/* these stylesheets are temporary */
// import './src/styles/reset.css'
// import './src/styles/variables.css'
// import './src/styles/global.css'

import './src/scss/main.scss';

export const wrapRootElement = ({ element }) => {
  return (
    <TrackingProvider>
      <AuthProvider>
        <StoreProvider>{element}</StoreProvider>
      </AuthProvider>
    </TrackingProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  const { pathname, href } = props.location;
  const renderSEO = () => {
    if (pathname.startsWith('/account')) {
      const handle = pathname.split('/').pop();
      const title = handle
        .split('-')
        .map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');

      return <SEO title={title} page={handle} link={href} />;
    }

    return null;
  };

  return (
    <>
      {renderSEO()}
      {element}
    </>
  );
};
