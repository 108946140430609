/* eslint-disable max-len */
import React, {
	useState, useCallback, useRef, useContext
} from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import classNames from 'classnames';

import { StoreContext } from '../../context/store-context';
import { AuthContext } from '../../context/auth-context';

import Link from '../../components/Link';
import Banner from '../../components/Banner';
import Navigation from '../../components/Navigation';
import Dashboard from '../../components/Dashboard';
// import Toast from '../../components/Toast';

import { useWindowEventListener } from '../../utils/hooks';
import renderIcon from '../../utils/render-icon';
import QuickSearch from '../../components/QuickSearch';

const ns = `site-header`;

const Header = ({pathname, location}) => {
	const [goingUp, setGoingUp] = useState(false);
	const [isSticky, setIsSticky] = useState(false);
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	// const [searchValue, setSearchValue] = useState('');
	// const [searchFocus, setSearchFocus] = useState(false);

	const {
		checkout,
		isFlyoutCartOpen,
		onFlyoutCartOpen,
		onFlyoutCartClose,
	} = useContext(StoreContext);

	const { state: authState } = useContext(AuthContext);
	const { auth = {} } = authState;

	const prevScrollY = useRef(0);

	const accountLinks = [{
		url: '/account/my-account',
		label: 'My Account',
	}, {
		url: '/account/orders',
		label: 'My Orders',
	}];

	const {
		wp: {
			acfOptionsHeader: {
				headerSettings: {
					bannerContent,
					bannerBackgroundColor,
					bannerTextColor,
					bannerFontSize,
					bannerFontWeight,
					bannerLetterSpacing,
					bannerWidth,
					bannerTextAlign,
					link
				}
			},
			globalSettings: {
				globalSettings: { mainNavigation, mainNavigationCard }
			}
		}
	} = useStaticQuery(
		graphql`
			query {
				wp {
					acfOptionsHeader {
						headerSettings {
							bannerContent
							bannerBackgroundColor
							bannerTextColor
							bannerFontSize
							bannerFontWeight
							bannerLetterSpacing
							bannerWidth
							bannerTextAlign
							link {
								label
								url
							}
						}
					}
					globalSettings {
						globalSettings {
							mainNavigation {
								fieldGroupName
								label
								highlightFirstLink
								megaMenu {
									label
									links {
										link {
											url
											title
											target
										}
									}
								}
								links {
									link {
										target
										title
										url
									}
								}
							}
							mainNavigationCard {
								eyebrow
								headline
								cta {
									target
									title
									url
								}
							}
						}
					}
				}
			}
		`
	);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--sticky`]: isSticky,
		[`${ ns }--show`]: goingUp,
	});

	useWindowEventListener('scroll', useCallback(() => {
		const { scrollY } = window;
		const currentScrollY = scrollY;

		if (prevScrollY.current < currentScrollY && goingUp) {
			setGoingUp(false);
		}
		if (prevScrollY.current > currentScrollY && !goingUp) {
			setGoingUp(true);
		}

		prevScrollY.current = currentScrollY;

		if (scrollY >= 41) {
			if (scrollY >= 121) {
				setIsSticky(true);
			}
		} else {
			setIsSticky(false);
		}
	}, [goingUp]));

	const items = checkout?.lineItems || [];

	const quantity = items.reduce((total, item) => {
		return total + item.quantity;
	}, 0);

	const handleCartClick = () => {
		return isFlyoutCartOpen ? onFlyoutCartClose() : onFlyoutCartOpen({ location });
	};

	// function that adds search-open class to header element when the search is open
	const toggleSearch = () => {
		setIsSearchOpen(!isSearchOpen);
	};

	const isHomePage = pathname === '/';
	const isSearchPage = /^\/search\/?$/i.test(pathname);

	return (
		<>
			{bannerContent && (
				<Banner
					content={bannerContent}
					bgColor={bannerBackgroundColor}
					textColor={bannerTextColor}
					fontSize={bannerFontSize}
					fontWeight={bannerFontWeight}
					letterSpacing={bannerLetterSpacing}
					width={bannerWidth}
					textAlign={bannerTextAlign}
					consumerSiteLinkOut={link}
				/>
			)}
			<div className={`${ ns }__container`}>
				<header className={`${ rootClassnames } ${ isSearchOpen && ` search-open` }`}>
					<div className={'container-fluid'}>
						<div className={`${ ns }__left`}>
							<Navigation
								hideDropdowns={!goingUp && isSticky}
								pathname={pathname}
								menuItems={mainNavigation}
								menuCard={mainNavigationCard}
								location={location}
							/>
						</div>
						<div className={`${ ns }__center`}>
							<Link to={'/'} className={'logo'}>
								<span className={`visually-hidden`}>Logo</span>
								{renderIcon('logo')}
							</Link>
						</div>
						{auth.isAuthenticated && (
							<div className={`${ ns }__right`}>
								{!isSearchPage && (
								<>
									<QuickSearch
										handleSearchOpen={toggleSearch}
										handleMobileNavClose={() => {}}
										location={location}
									/>
									<div className={`${ ns }__icon-seperator`} />
								</>
								)}
								<Link to={`/account/my-account`} variant={'navy'} className={`${ ns }__icon ${ ns }__icon-account`} sameTab>
									<span className={`visually-hidden`}>Account</span>
									{renderIcon('account')}
								</Link>
								<button
									onClick={handleCartClick}
									aria-label={`Shopping Cart with ${ quantity } items`}
									className={`${ ns }__icon ${ ns }__icon-cart`}
								>
									{renderIcon('cart')}
									{quantity > 0 && <div className={`${ ns }__icon-cart-badge`}>{quantity}</div>}
								</button>
							</div>
						)}
						{!auth.isAuthenticated && (
							<div className={`${ ns }__right`}>
								<QuickSearch
									handleSearchOpen={toggleSearch}
									handleMobileNavClose={() => {}}
									location={location}
								/>
								<div className={`${ ns }__icon-seperator`} />
								<Link to={'/account/login'} variant={'sign-in'}>
									Sign In
								</Link>
								<Link to={'/account/register'} variant={'create-account'}>
									Create Account
								</Link>
							</div>
						)}
					</div>
				</header>
				{auth.isAuthenticated && isHomePage && (
					<Dashboard
						accountLinks={accountLinks}
					/>
				)}
			</div>
		</>
	);
};

export default Header;
