import React from 'react';

const LinkedIn = () => {
	return (
		<svg width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
			<path d="M22.23 0H1.77A1.77 1.77 0 0 0 0 1.77v20.46A1.77 1.77 0 0 0 1.77 24h20.46A1.77 1.77 0 0 0 24 22.23V1.77A1.77 1.77 0 0 0 22.23 0zM7.12 20.45H3.57V9h3.55v11.45zM5.34 7.53a2.06 2.06 0 1 1 0-4.12 2.06 2.06 0 0 1 0 4.12zM20.45 20.45h-3.55v-5.58c0-1.33-.02-3.04-1.85-3.04-1.85 0-2.13 1.45-2.13 2.94v5.68H9.37V9h3.41v1.56h.05c.47-.88 1.61-1.8 3.32-1.8 3.55 0 4.21 2.34 4.21 5.38v6.31z" fill={'currentColor'} />
		</svg>

	);
};

export default LinkedIn;
