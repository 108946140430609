import React, { useContext, useState, useRef } from 'react';
import classNames from 'classnames';

import Modal from '../Modal';
import Button from '../Button';
import { AuthContext } from '../../context/auth-context';

const ns = `register-account`;

const COLOR = {
	SUCCESS: '#00a1c6',
	ALERT: '#ff2600',
	INFO: '#003c77',
};

const ConfirmationModal = ({
	onRequestClose,
  onVerificationAction,
	onSignOutAction,
	isOpen,
  isVerifying,
  isVerificationAlertVisible,
	modalProps = {},
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const { actions: authActions } = useContext(AuthContext);
	const [emailStatusMsg, setEmailStatusMsg] = useState(undefined);
	const [emailStatusColor, setEmailStatusColor] = useState(undefined);
	const messageTimeout = useRef(null);

	const close = () => {
		if (typeof onRequestClose === 'function') {
			onRequestClose();
		}
	};

  const handleCTAClick = () => {
    const actionFn = typeof onVerificationAction === 'function'
			? onVerificationAction
			: close;
    actionFn();
  };

	const handleEmailResend = async () => {
		clearTimeout(messageTimeout.current);
		setEmailStatusMsg('Sending verification email...');
		setEmailStatusColor(COLOR.INFO);

		try {
			const { status } = await authActions.sendVerificationEmail();
			if (status === 'pending' || status === 'completed') {
				setEmailStatusMsg('Verification link sent');
				setEmailStatusColor(COLOR.SUCCESS);
			} else {
				throw Error;
			}
		} catch (error) {
			console.error(error);
			setEmailStatusMsg('We\'re unable to resend a verification link to your email. Please try again.');
			setEmailStatusColor(COLOR.ALERT);
		}

		messageTimeout.current = setTimeout(setEmailStatusMsg, 15000);
	};

	const emailButtonMarkup = !emailStatusMsg && (
		<Button
			className={`${ ns }__modal-button-email`}
			variant="text"
			onClick={handleEmailResend}
		>
			Resend verification email
		</Button>
	);

	const emailMessageMarkup = !!emailStatusMsg && (
		<p
			className={`${ ns }__modal-message`}
			style={{ color: emailStatusColor }}
		>
			{emailStatusMsg}
		</p>
	);

	const signOutButtonMarkup = typeof onSignOutAction === 'function' && (
		<Button
			className={`${ ns }__modal-button`}
			variant="white"
			onClick={onSignOutAction}
		>
			Sign Out
		</Button>
	);

	const verificationAlertMarkup = !!isVerificationAlertVisible && (
		<p className={`${ ns }__modal-text error`}>
			<small>
				Please verify your email before continuing
			</small>
		</p>
	);

	return (
		<Modal
			modifier={rootClassnames}
			isOpen={isOpen}
			onRequestClose={close}
			{...modalProps}
		>
			<div className={`${ ns }__modal-content`}>
				<h3 className={`${ ns }__modal-title`}>
          A verification link has been sent to your email address
				</h3>
				<p className={`${ ns }__modal-text`}>
          Please click on the link that has been sent to your email address to
          verify your email
				</p>
        {verificationAlertMarkup}
				<div className={`${ ns }__modal-button-wrap`}>
					<Button
						className={`${ ns }__modal-button`}
						onClick={handleCTAClick}
						isLoading={isVerifying}
					>
						I&apos;ve verified my email
					</Button>
					{signOutButtonMarkup}
				</div>
				{emailButtonMarkup}
				{emailMessageMarkup}
				<p className={`${ ns }__modal-text`}>
					Questions? Contact us at
					<br />
					<br />
					<strong>prosales@nordicnaturals.com</strong>
					<br />
					<strong>800.662.2544</strong>
				</p>
			</div>
		</Modal>
	);
};

export default ConfirmationModal;
