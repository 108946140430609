import * as React from 'react';
import { createClient, Provider as UrlqProvider } from 'urql';
import { KlevuConfig } from '@klevu/core';

export const urqlClient = createClient({
	url: `https://${ process.env.GATSBY_SHOPIFY_STORE_URL }/api/${ process.env.GATSBY_SHOPIFY_STOREFRONT_API_VERSION }/graphql.json`,
	fetchOptions: {
		headers: {
			'X-Shopify-Storefront-Access-Token':
        process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
		},
	},
});

KlevuConfig.init({
	url: `${ process.env.GATSBY_KLEVU_API_URL }`,
	apiKey: `${ process.env.GATSBY_KLEVU_API_KEY }`,
});

export function SearchProvider({ children }) {
	return <UrlqProvider value={urqlClient}>{children}</UrlqProvider>;
}
