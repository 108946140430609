import React, {
	useState, useEffect, useRef, useContext
} from 'react';
import classNames from 'classnames';

import { navigate } from 'gatsby';
import Link from '../Link';

import { AuthContext } from '../../context/auth-context';
import { PRACTITIONER_STATUS } from '../../data/constants';
import { getMessagingForProtectedContent } from '../../utils/messaging';

import renderIcon from '../../utils/render-icon';
import QuickSearch from '../QuickSearch';

const ns = `nav-mobile-menu`;

const NavMobileMenu = ({onCloseCallback, open, menuItems, location}) => {
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [mobileNavOpened, setMobileNavOpened] = useState(false);
	const [subNavOpened, setSubNavOpened] = useState(false);
	const [currentSubNavOpened, setCurrentSubNavOpened] = useState('');

	const { state: authState, actions: authActions } = useContext(AuthContext);
	const { auth, practitioner } = authState;
	const practitionerStatus = practitioner.status || '';

	const isUserStatusActive = practitionerStatus.toUpperCase() === PRACTITIONER_STATUS.ACTIVE;

	useEffect(() => {
		setMobileNavOpened(open);
	}, [open]);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`mobilenav-opened`]: mobileNavOpened,
		[`mobilenav-closed`]: !mobileNavOpened,
	});

	const openSubNav = (e, subNav) => {
		setSubNavOpened(true);
		setCurrentSubNavOpened(subNav);
	};

	const closeSubNav = () => {
		setSubNavOpened(false);
		setCurrentSubNavOpened('');
	};

	const closeMobileMenu = e => {
		if (e.target.classList[0] === ns) {
			setMobileNavOpened(false);
			onCloseCallback();
		}
	};

	const closeAllNavs = () => {
		setMobileNavOpened(false);
		setSubNavOpened(false);
		setCurrentSubNavOpened('');
		onCloseCallback();
	};

	const toggleMobileSearch = () => {
		setIsSearchOpen(!isSearchOpen);
	};

	const handleSubmitCloses = () => {
		setMobileNavOpened(false);
		setSubNavOpened(false);
		setCurrentSubNavOpened('');
		setIsSearchOpen(false);
		onCloseCallback();
	};

	return (
	// eslint-disable-next-line
		<div className={rootClassnames} onClick={(e) => { return closeMobileMenu(e); }} role={'main'}>
			<div className={`${ ns }__container `}>
				<div className={`${ ns }__parent-menu ${ subNavOpened ? 'sub-nav-opened' : '' }`}>
					<div className={`${ ns }__menu-search`}>
						<div className={`${ ns }__quicksearch ${ isSearchOpen ? 'search-open' : '' }`}>
							<QuickSearch
								handleSearchOpen={toggleMobileSearch}
								handleMobileNavClose={handleSubmitCloses}
								location={location}
							/>
						</div>
					</div>
					<button className={`${ ns }__parent-menu-item`} onClick={e => { return openSubNav(e, 'Shop'); }}>
						<p>Shop</p>
						{renderIcon('carrot-right')}
					</button>
					<button className={`${ ns }__parent-menu-item`} onClick={e => { return openSubNav(e, 'Learn'); }}>
						<p>Learn</p>
						{renderIcon('carrot-right')}
					</button>
					<button className={`${ ns }__parent-menu-item`} onClick={e => { return openSubNav(e, 'Why Nordic'); }}>
						<p>Why Nordic</p>
						{renderIcon('carrot-right')}
					</button>

					{/*
					<div className={`${ ns }__menu-item`}>
						<Link
							to={`/`}
							variant={'white'}
							onClick={() => {
								setMobileNavOpened(false);
								onFlyoutCartOpen();
							}}
						>
							{renderIcon('cart')}
							<p>Bag</p>
						</Link>
					</div>
					*/}

					<div className={`${ ns }__menu-item`}>
						<Link
							to={auth.isAuthenticated ? `/account/my-account` : `/account/login`}
							variant={'white'}
							onClick={() => { closeAllNavs(); }}
						>
							{renderIcon('account')}
							<p>Account</p>
						</Link>
					</div>
				</div>
				<div className={`${ ns }__submenu ${ currentSubNavOpened === 'Shop' ? 'opened' : '' }`}>
					<button onClick={() => { return closeSubNav(); }}>
						{renderIcon('carrot-left')}
						ALL
					</button>

					<div className={`${ ns }__submenu-section ${ menuItems.shop[0].highlight ? 'highlight-first' : '' }`}>
						<p className={`title`}>Shop</p>
						{menuItems.shop[0] && menuItems.shop[0].links.map(value => {
							return (
								<Link
									key={value.label}
									className={'nav-link'}
									to={value.url}
									variant={'white'}
									onClick={() => { return (closeAllNavs()); }}
								>
									{value.label}
								</Link>
							);
						})}
					</div>
					{menuItems.shop.map((value, index) => {
						if (index > 0) {
							return (
								<div className={`${ ns }__submenu-section`} key={value.title}>
									<p className={`title`}>{value.title}</p>
									{value.links.map(link => {
										return (
											<Link
												className={'nav-link'}
												to={link.url}
												key={link.label}
												variant={'white'}
												onClick={() => { return closeAllNavs(); }}
											>
												{link.label}
											</Link>
										);
									})}
								</div>
							);
						}
						return true;
					})}

				</div>
				<div className={`${ ns }__submenu ${ currentSubNavOpened === 'Why Nordic' ? 'opened' : '' }`}>
					<button onClick={() => { return closeSubNav(); }}>
						{renderIcon('carrot-left')}
						ALL
					</button>
					<div className={`${ ns }__submenu-section`}>
						<p className={`title`}>Why Nordic</p>
						{menuItems.whyNordic[0].links.map(link => {
							const clickHandler = () => {
								closeAllNavs();
							};

							return (
								<Link
									key={link.label}
									className={'nav-link'}
									to={link.url}
									variant={'white'}
									onClick={clickHandler}
								>
									{link.label}
								</Link>
							);
						})}

					</div>
				</div>
				<div className={`${ ns }__submenu ${ currentSubNavOpened === 'Learn' ? 'opened' : '' }`}>
					<button onClick={() => { return closeSubNav(); }}>
						{renderIcon('carrot-left')}
						ALL
					</button>
					<div className={`${ ns }__submenu-section`}>
						<p className={`title`}>Learn</p>
						{menuItems.learn[0] && menuItems.learn[0].links.map(link => {
							let linkUrl = link.url;
							let clickHandler = () => {
								closeAllNavs();
							};

							//	--------------------------
							//	Protected Content Handling
							//	--------------------------
							const isProtectedContent = /gateway\.on24\.com/.test(linkUrl);

							if (isProtectedContent && !isUserStatusActive) {
								const message = getMessagingForProtectedContent(false, true);
								linkUrl = '#';
								clickHandler = () => {
									window.alert(message);
									closeAllNavs();
								};
							}

							return (
								<Link
									key={link.label}
									className={'nav-link'}
									to={linkUrl}
									variant={'white'}
									onClick={clickHandler}
								>
									{link.label}
								</Link>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavMobileMenu;
