/* eslint-disable no-plusplus */

import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import { noop } from '../utils';

const UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

const initialState = { isInitialized: false, utm: {} };
const initialActions = { getTrackingObj: noop };

export const TrackingContext = createContext({
  state: initialState,
  actions: initialActions,
});

export const TrackingProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(initialState.isInitialized);
  const [utm, setUTM] = useState(initialState.utm);

  const getTrackingObj = useCallback(() => {
    if (typeof window === 'undefined' || typeof localStorage === 'undefined') {
      return {};
    }

    if (isInitialized) {
      return utm;
    }

    const trackingObj = {};
    const searchParams = new URLSearchParams(window.location.search);
    const hasUTMParams = UTM_KEYS.some(key => searchParams.has(key));

    /**
     * Fill trackingObj with storage value first.
     * If search params contain any UTM parameters we either:
     *  a. Replace the value if value is truthy
     *  b. Delete the key if value is falsy
     */
    for (let i = 0; i < UTM_KEYS.length; i++) {
      const utmKey = UTM_KEYS[i];
      const utmStorageValue = localStorage.getItem(utmKey);
      const utmParameterValue = searchParams.get(utmKey);

      if (utmStorageValue) {
        trackingObj[utmKey] = utmStorageValue;
      }

      if (hasUTMParams) {
        if (utmParameterValue) {
          trackingObj[utmKey] = utmParameterValue;
        } else {
          delete trackingObj[utmKey];
        }
      }
    }

    return trackingObj;
  }, [isInitialized, utm]);

  useEffect(() => {
    const trackingObj = getTrackingObj();

    for (let i = 0; i < UTM_KEYS.length; i++) {
      const key = UTM_KEYS[i];

      if (key in trackingObj) {
        localStorage.setItem(key, trackingObj[key]);
      } else {
        localStorage.removeItem(key);
      }
    }

    setUTM(trackingObj);
    setIsInitialized(true);
  }, []);

  const providerValue = useMemo(() => ({
    state: { isInitialized, utm },
    actions: { getTrackingObj },
  }), [isInitialized, utm, getTrackingObj]);

  return (
    <TrackingContext.Provider value={providerValue}>
      {children}
    </TrackingContext.Provider>
  );
};
