import React, { useEffect } from 'react';
import classNames from 'classnames';

import SineWaveUtil from '../../utils/sine-wave';

const ns = `sine-wave`;

const SineWave = ({
	containerId,
	canvasId,
	topColor,
	bottomColor,
	waveHeight,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	useEffect(() => {
		const SineWaveUtilInstance = new SineWaveUtil(
			containerId,
			canvasId,
			topColor,
			bottomColor,
			waveHeight
		);
		SineWaveUtilInstance.init();
	}, []);

	if (!containerId || !canvasId) {
		return null;
	}

	return (
		<div className={rootClassnames} id={containerId}>
			<canvas id={canvasId} />
		</div>
	);
};

export default SineWave;
