/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

import React, {
	createContext,
	useState,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import klaviyo from '../utils/klaviyo';
import { isObjectDifferent, normalize, noop, gidUrlToId } from '../utils';
import { auth0, shopify, salesforce, Nordic } from '../utils/clients';
import {
	emptyAddress,
	emptyDBA,
	emptySalesforceAddress,
	practiceTypeOptions,
	specialtyOptions,
} from '../data';
import { REGISTRATION_STATUS, PRACTITIONER_STATUS } from '../data/constants';
import {
	AUTH_STORAGE_KEY,
	USE_TEST_DATA,
	MY_FIRST_NAME,
	MY_LAST_NAME,
	MY_EMAIL,
	MY_PASSWORD,
} from '../utils/config';

//	---------------------
//	Development Test Data
//	---------------------
const testData = {
	formFields: undefined,
};

if (USE_TEST_DATA) {
	testData.formFields = {
		firstName: MY_FIRST_NAME,
		lastName: MY_LAST_NAME,
		email: MY_EMAIL,
		password: MY_PASSWORD,
		verify: MY_PASSWORD,
		companyName: 'ENVOY',
		phone: '0000000000',
		practiceType: practiceTypeOptions[0],
		specialty: specialtyOptions[0],
	};
}

//	-------------
//	Initial State
//	-------------
const initialState = {
	isInitializing: false,
	isInitialized: false,
	authLoading: true,
	isSeeded: false,
	userPropertiesLoaded: false,
	userProperties: {},
	shopifyCustomer: {
		hasFetched: false,
		id: '',
		email: '',
		defaultAddress: null,
		addresses: [],
		orders: [],
	},
	practitioner: {
		hasFetched: false,
		firstName: '',
		lastName: '',
		email: '',
		emailOptIn: false,
		shopifyCustomerId: '',
		interests: [],
		additionalComments: '',
		companyName: '',
		status: '',
	},
	business: {
		hasFetched: false,
		shipping: {
			address: emptySalesforceAddress,
			recipientName: '',
			preferredMethod: '',
		},
		billing: {
			address: emptySalesforceAddress,
		},
		website: '',
	},
	documentStatus: {
		hasFetched: false,
		license: { exists: false },
		reseller_certificate: { exists: false },
	},
	mapPolicy: {
		hasFetched: false,
		dba: {
			address_physical: {
				company: '',
				address1: '',
				address2: '',
				city: '',
				province_code: '',
				zip: '',
				phone: '',
				email: '',
			},
			address_mailing: {
				address1: '',
				address2: '',
				city: '',
				province_code: '',
				zip: '',
			},
		},
		online_storefronts: [],
		fulfillment_centers: [],
	},
	formFields: {
		firstName: String(),
		lastName: String(),
		email: String(),
		password: String(),
		verify: String(),
		companyName: String(),
		comments: String(),
		practiceType: String(),
		specialty: String(),
		phone: String(),
		website: String(),
		billingEmail: String(),
		interests: [],
		emailOptIn: true,
		isBillingAndAccountEmailSame: true,
		isBillingSameAsShipping: true,
		isSubmittingLicense: false,
		isSubmittingCertificate: false,
		isDBA: null,
		isResellingOnline: null,
		isFulfillmentCenter: null,
		billingAddress: emptyAddress,
		businessAddress: emptyAddress,
		shippingAddresses: [emptyAddress],
		dbaInfo: emptyDBA,
		onlineStorefronts: [],
		fulfillmentCenters: [],
		...testData.formFields,
	},
	auth: {
		isAuthenticating: false,
		isAuthenticated: false,
		isAuthorized: false,
		idToken: '',
		accessToken: '',
		refreshToken: '',
		expiresAt: 0,
		profile: {
			email: '',
			emailVerified: false,
			firstName: '',
			fullName: '',
			hasFetched: false,
			lastName: '',
			nickname: '',
			sub: '',
			userId: '',
			metadata: {},
			shopify: {
				multipassToken: '',
				customer: {},
			},
		},
		shopifyCustomerToken: {
			accessToken: '',
			expiresAt: '',
		},
	},
};

const initialActions = {
	changePassword: noop,
	signIn: noop,
	signOut: noop,
	registrationRoutineS1A: noop,
	registrationRoutineS1B: noop,
	registrationRoutineS2: noop,
	registrationRoutineS3: noop,
	registrationRoutineS4: noop,
	setFormFields: noop,
	resetForm: noop,
	populateStateWithDb: noop,
	fetchPractitioner: noop,
	putPractitioner: noop,
	fetchBusiness: noop,
	putBusiness: noop,
	fetchDocumentStatus: noop,
	putLicense: noop,
	putCertificate: noop,
	fetchShopifyCustomer: noop,
	createShopifyAddress: noop,
	updateShopifyAddress: noop,
	deleteShopifyAddress: noop,
	setDefaultShopifyAddress: noop,
	getAuth0Profile: noop,
	onboardLicense: noop,
	onboardCertificate: noop,
	sendVerificationEmail: noop,
};

export const AuthContext = createContext({
	state: initialState,
	actions: initialActions,
});

export const AuthProvider = ({ children }) => {
	const [isInitializing, setIsInitializing] = useState(
		initialState.isInitializing
	);
	const [isInitialized, setisInitialized] = useState(
		initialState.isInitialized
	);
	const [authLoading, setAuthLoading] = useState(initialState.authLoading);
	const [userProperties, setUserProperties] = useState(initialState.userProperties);
	const [userPropertiesLoaded, setUserPropertiesLoaded] = useState(initialState.userPropertiesLoaded);
	const [isSeeded, setIsSeeded] = useState(initialState.isSeeded);
	const [auth, setAuth] = useState(initialState.auth);
	const [formFields, setFormFields] = useState(initialState.formFields);
	const [practitioner, setPractitioner] = useState(initialState.practitioner);
	const [business, setBusiness] = useState(initialState.business);
	const [mapPolicy, setMapPolicy] = useState(initialState.mapPolicy);
	const [documentStatus, setDocumentStatus] = useState(
		initialState.documentStatus
	);
	const [shopifyCustomer, setShopifyCustomer] = useState(
		initialState.shopifyCustomer
	);
	const shopifyInterval = useRef(null);
	const auth0Interval = useRef(null);

	const refreshMultipassToken = (idToken) => (claims, shouldForce) => {
		const { customer, multipassToken } = claims;
		const tenMinutes = 1000 * 60 * 10;
		const nordic = new Nordic(idToken);
		const currentDt = Date.now();
		const createdAt = customer?.created_at || null;
		const createdAtDt = new Date(createdAt).getTime();
		const timeElapsed = currentDt - createdAtDt;

		if (!multipassToken || timeElapsed >= tenMinutes || shouldForce) {
			return nordic.getMultipassToken();
		}

		return claims;
	};

	const resetForm = () => {
		setFormFields(initialState.formFields);
		setIsSeeded(initialState.isSeeded);
	};

	const signOut = () => {
		setAuth(initialState.auth);
		setPractitioner(initialState.practitioner);
		setBusiness(initialState.business);
		setDocumentStatus(initialState.documentStatus);
		setShopifyCustomer(initialState.shopifyCustomer);
		resetForm();
		window.localStorage.removeItem(AUTH_STORAGE_KEY);
	};

	/**
	 * @param {object} body
	 * @param {string} [body.email]
	 * @param {string} [body.password]
	 * @param {string} [body.username]
	 * @param {string} [body.accessToken]
	 * @param {string} [body.idToken]
	 * @param {string} [body.refreshToken]
	 * @param {number} [body.expiresAt]
	 * @param {object} [body.shopifyCustomerToken]
	 * @param {string} [body.shopifyCustomerToken.accessToken]
	 * @param {string} [body.shopifyCustomerToken.expiresAt]
	 * @returns void
	 */
	const signIn = async (body) => {
		try {
			setAuth({ ...auth, isAuthenticating: true });

			body.email = body.email || body.username;
			body.username = body.username || body.email;

			const hasCredentials = !!body.password && !!body.email;
			const { idToken, accessToken, refreshToken, expiresAt } = hasCredentials
				? await auth0.login(body)
				: await auth0.refreshToken(body);

			const auth0Profile = await auth0.userInfo(accessToken);
			const profile = normalize.profile.fromAuth0(auth0Profile, {
				hasFetched: true,
			});
			profile.shopify = await refreshMultipassToken(idToken)(profile.shopify);
			const { multipassToken } = profile.shopify;

			const shopifyCustomerToken =
				await shopify.createCustomerTokenWithMultipass(multipassToken);

			//	Some SalesForce Practitioners are pre-provisioned by the administrators with status 'active' .
			//	But those Practitioners do not have a Shopify Customer.id associated with them yet.
			//	Therefore, we update SalesForce with the shopifyCustomerId on every signIn .
			//	We toyed with the idea of only delivering this update if needed,
			//	but found it simpler and more reliable to constantly keep SalesForce updated on every signIn.
			const salesforceClient = new salesforce.Client({ accessToken, idToken });

			const dbShopifyCustomer = await shopify.getCustomer(shopifyCustomerToken.accessToken);
			if (!profile?.shopify?.customer?.firstName) {
				profile.shopify.customer.firstName = dbShopifyCustomer.firstName;
			}

			const shopifyCustomerId = dbShopifyCustomer.id.split('/').pop();
			let dbPractitioner = await salesforceClient
				.getCurrentPractitioner()
				.catch(() => ({}));

			if (String(dbPractitioner.shopifyCustomerId) !== shopifyCustomerId) {
				dbPractitioner = await salesforceClient.createOrUpdatePractitioner({
					...dbPractitioner,
					shopifyCustomerId,
				});
			}

			const nextAuthState = {
				...auth,
				idToken,
				accessToken,
				refreshToken,
				expiresAt,
				profile,
				shopifyCustomerToken,
				isAuthenticated: true,
				isAuthenticating: false,
			};

			if (hasCredentials) {
				const customerProperties = {
					customer_address_1: dbShopifyCustomer?.defaultAddress?.address1 || '',
					customer_address_2: dbShopifyCustomer?.defaultAddress?.address2 || '',
					customer_city: dbShopifyCustomer?.defaultAddress?.city || '',
					customer_country: dbShopifyCustomer?.defaultAddress?.country || '',
					customer_email: body?.email || '',
					customer_first_name: dbShopifyCustomer?.firstName || '',
					customer_id: gidUrlToId(dbShopifyCustomer?.id) || '',
					customer_last_name: dbShopifyCustomer?.defaultAddress?.lastName || '',
					customer_order_count: dbShopifyCustomer?.numberOfOrders || '',
					customer_phone: normalize.phone(dbShopifyCustomer?.defaultAddress?.phone) || '',
					customer_province: dbShopifyCustomer?.defaultAddress?.province || '',
					customer_province_code: dbShopifyCustomer?.defaultAddress?.provinceCode || '',
					customer_zip: dbShopifyCustomer?.defaultAddress?.zip || '',

					// The following field is required
					visitor_type: "logged_in"
				};

				window.ElevarDataLayer = window.ElevarDataLayer ?? [];
				window.ElevarDataLayer.push({
					event: "dl_login",
					user_properties: customerProperties
				});
			}

			setAuth(nextAuthState);
			setPractitioner({ ...dbPractitioner, hasFetched: true });
			setShopifyCustomer({ ...dbShopifyCustomer, hasFetched: true });
			setBusiness(initialState.business);
			setDocumentStatus(initialState.documentStatus);
			resetForm();
			window.localStorage.setItem(
				AUTH_STORAGE_KEY,
				JSON.stringify(nextAuthState)
			);
		} catch (error) {
			setAuth({ ...auth, isAuthenticating: false });
			throw error;
		}
	};

	const changePassword = (userEmail) => {
		const { profile = {} } = auth;
		const email = userEmail || profile.email;
		return auth0.changePassword(email);
	};

	const sendVerificationEmail = () => {
		const { profile = {} } = auth;
		return auth0.sendVerificationEmail(profile.sub);
	};

	// Try Authenticate
	useEffect(() => {
		(async () => {
			setIsInitializing(true);

			try {
				const storageValue = window.localStorage.getItem(AUTH_STORAGE_KEY);
				const authStorage = JSON.parse(storageValue);
				const searchParams = new URLSearchParams(window.location.search);
				const code = searchParams.get('code');
				let credentials = authStorage;

				if (code) {
					const authResponse = await auth0.codeExchange(code).catch((error) => {
						console.error(error.json || error);
						return {};
					});

					credentials = { ...credentials, ...authResponse };
				}

				if (!isEmpty(credentials)) {
					await signIn(credentials);
				}
			} catch (error) {
				console.error(error.json || error);
				signOut();
			}

			setIsInitializing(false);
			setisInitialized(true);
			setAuthLoading(false);
		})();
	}, []);

	useEffect(() => {
		const { isAuthenticated, shopifyCustomerToken } = auth;
		const refreshCustomerToken = async () => {
			try {
				const customerToken = await shopify.refreshToken(shopifyCustomerToken);
				if (shopifyCustomerToken.accessToken !== customerToken.accessToken) {
					setAuth({ ...auth, shopifyCustomerToken: customerToken });
				}
			} catch (error) {
				console.error(error.json || error);
				signOut();
			}
		};

		const duration = 1000 * 60 * 60 * 5;
		clearInterval(shopifyInterval.current);

		if (isAuthenticated && shopifyCustomerToken.accessToken) {
			shopifyInterval.current = setInterval(refreshCustomerToken, duration);
			refreshCustomerToken();
		}

		return () => {
			clearInterval(shopifyInterval.current);
		};
	}, [auth.isAuthenticated, auth.shopifyCustomerToken.accessToken]);

	useEffect(() => {
		const { isAuthenticated, refreshToken } = auth;
		const refreshAuth0Token = async () => {
			try {
				const auth0Token = await auth0.refreshToken(auth);
				if (refreshToken !== auth0Token.refreshToken) {
					setAuth({ ...auth, ...auth0Token });
				}
			} catch (error) {
				console.error(error.json || error);
				signOut();
			}
		};

		const duration = 1000 * 60 * 60 * 2;
		clearInterval(auth0Interval.current);

		if (isAuthenticated && refreshToken) {
			auth0Interval.current = setInterval(refreshAuth0Token, duration);
			refreshAuth0Token();
		}

		return () => {
			clearInterval(auth0Interval.current);
		};
	}, [auth.isAuthenticated, auth.refreshToken]);

	useEffect(() => {
		const { isAuthenticated, isAuthorized } = auth;
		const status = practitioner.status || '';
		const isActive = status.toUpperCase() === PRACTITIONER_STATUS.ACTIVE;

		if (isAuthenticated && isAuthorized !== isActive) {
			setAuth({ ...auth, isAuthorized: isActive });
		}
	}, [auth.isAuthenticated, auth.isAuthorized, practitioner.status]);

	useEffect(() => {
		const { isAuthenticated, profile = {} } = auth;
		const { email, firstName, lastName } = profile;

		if (isAuthenticated && email) {
			klaviyo.identify({
				$email: email,
				$first_name: firstName,
				$last_name: lastName,
			});
		}
	}, [
		auth.isAuthenticated,
		auth.profile.email,
		auth.profile.firstName,
		auth.profile.lastName,
	]);

	const fetchPractitioner = async (shouldForce = false) => {
		const { idToken, accessToken } = auth;

		if (practitioner.hasFetched && !shouldForce) {
			return practitioner;
		}

		const salesforceClient = new salesforce.Client({ idToken, accessToken });
		const dbPractitioner = await salesforceClient.getCurrentPractitioner();

		if (dbPractitioner) {
			const nextPractitionerState = {
				...practitioner,
				...dbPractitioner,
				hasFetched: true,
			};
			setPractitioner(nextPractitionerState);
			return nextPractitionerState;
		}

		return practitioner;
	};

	const putPractitioner = async (data, token, shouldForce = false) => {
		token = token || {};
		const hasUpdates = isObjectDifferent(practitioner, data);

		if (!hasUpdates && !shouldForce) {
			return practitioner;
		}

		const idToken = token.idToken || auth.idToken;
		const accessToken = token.accessToken || auth.accessToken;
		const salesforceClient = new salesforce.Client({ idToken, accessToken });
		const dbPractitioner = await salesforceClient.createOrUpdatePractitioner(
			data
		);
		const nextPractitionerState = { ...practitioner, ...dbPractitioner };
		setPractitioner({ ...practitioner, ...dbPractitioner });

		return nextPractitionerState;
	};

	const fetchBusiness = async (shouldForce = false) => {
		const { idToken, accessToken } = auth;

		if (business.hasFetched && !shouldForce) {
			return business;
		}

		const salesforceClient = new salesforce.Client({ idToken, accessToken });
		const dbBusiness = await salesforceClient.getCurrentBusiness();

		if (dbBusiness) {
			const nextBusinessState = {
				...business,
				...dbBusiness,
				hasFetched: true,
			};
			setBusiness(nextBusinessState);

			return nextBusinessState;
		}

		return business;
	};

	const postBusiness = async (data, token) => {
		token = token || {};

		const idToken = token.idToken || auth.idToken;
		const accessToken = token.accessToken || auth.accessToken;
		const salesforceClient = new salesforce.Client({ idToken, accessToken });
		await salesforceClient.createBusiness(data);
	};

	// const putBusiness = async (data, token, shouldForce = false) => {
	// 	token = token || {};
	// 	const hasUpdates = isObjectDifferent(business, data);

	// 	if (!hasUpdates && !shouldForce) {
	// 		return business;
	// 	}

	// 	const idToken = token.idToken || auth.idToken;
	// 	const accessToken = token.accessToken || auth.accessToken;
	// 	const salesforceClient = new salesforce.Client({ idToken, accessToken });
	// 	const dbBusiness = await salesforceClient.createOrUpdateBusiness(data);
	// 	const nextBusinessState = { ...business, ...dbBusiness };
	// 	setBusiness(nextBusinessState);

	// 	return nextBusinessState;
	// };

	// const fetchDocumentStatus = async (shouldForce = false) => {
	// 	const { idToken, accessToken } = auth;

	// 	if (documentStatus.hasFetched && !shouldForce) {
	// 		return documentStatus;
	// 	}

	// 	const salesforceClient = new salesforce.Client({ idToken, accessToken });
	// 	const dbDocumentStatus = await salesforceClient.getCurrentDocumentStatus();

	// 	if (dbDocumentStatus) {
	// 		const nextDocumentStatus = {
	// 			...documentStatus,
	// 			...dbDocumentStatus,
	// 			hasFetched: true,
	// 		};
	// 		setDocumentStatus(nextDocumentStatus);

	// 		return nextDocumentStatus;
	// 	}

	// 	return documentStatus;
	// };

	const putLicense = async (file, token) => {
		token = token || {};
		const idToken = token.idToken || auth.idToken;
		const accessToken = token.accessToken || auth.accessToken;
		const salesforceClient = new salesforce.Client({ idToken, accessToken });
		await salesforceClient.createOrUpdateLicense(file);
		const nextDocumentStatus = { ...documentStatus, license: { exists: true } };
		setDocumentStatus(nextDocumentStatus);
		return nextDocumentStatus;
	};

	const putCertificate = async (file, token) => {
		token = token || {};
		const idToken = token.idToken || auth.idToken;
		const accessToken = token.accessToken || auth.accessToken;
		const salesforceClient = new salesforce.Client({ idToken, accessToken });
		await salesforceClient.createOrUpdateCertificate(file);
		const nextDocumentStatus = {
			...documentStatus,
			reseller_certificate: { exists: true },
		};
		setDocumentStatus(nextDocumentStatus);
		return nextDocumentStatus;
	};

	const putMapPolicy = async (data, token, shouldForce = false) => {
		token = token || {};
		const hasUpdates = isObjectDifferent(business, data);

		if (!hasUpdates && !shouldForce) {
			return business;
		}

		const idToken = token.idToken || auth.idToken;
		const accessToken = token.accessToken || auth.accessToken;
		const salesforceClient = new salesforce.Client({ idToken, accessToken });
		const dbMapPolicy = await salesforceClient.createOrUpdateMapPolicy(data);
		const nextMapPolicyState = { ...mapPolicy, ...dbMapPolicy };
		setMapPolicy(nextMapPolicyState);

		return nextMapPolicyState;
	};

	const fetchShopifyCustomer = async (shouldForce = false) => {
		const { accessToken } = auth.shopifyCustomerToken || {};

		if (shopifyCustomer.hasFetched && !shouldForce) {
			return shopifyCustomer;
		}

		const dbShopifyCustomer = await shopify.getCustomer(accessToken);

		if (dbShopifyCustomer.id) {
			const nextShopifyCustomer = {
				...shopifyCustomer,
				...dbShopifyCustomer,
				hasFetched: true,
			};
			setShopifyCustomer(nextShopifyCustomer);

			return nextShopifyCustomer;
		}

		return shopifyCustomer;
	};

	const createShopifyAddress = async (address, accessToken) => {
		const { accessToken: storeToken } = auth.shopifyCustomerToken;
		const customerToken = accessToken || storeToken;
		const createdAddress = await shopify.createAddress(customerToken, address);
		const nextAddressesState = shopifyCustomer.addresses.concat(createdAddress);
		setShopifyCustomer({ ...shopifyCustomer, addresses: nextAddressesState });

		return createdAddress;
	};

	const updateShopifyAddress = async (
		id,
		address,
		accessToken,
		shouldForce = false
	) => {
		const { accessToken: storeToken } = auth.shopifyCustomerToken;
		const customerToken = accessToken || storeToken;
		const prevAddress = shopifyCustomer.addresses.find((a) => a.id === id);
		const hasUpdates = isObjectDifferent(prevAddress, address);

		if (!hasUpdates && !shouldForce) {
			return prevAddress;
		}

		const updatedAddress = await shopify.updateAddress(
			customerToken,
			id,
			address
		);
		const defaultAddress = shopifyCustomer.defaultAddress || {};
		const nextAddressesState = shopifyCustomer.addresses.map((a) => {
			return a.id === id ? updatedAddress : a;
		});

		setShopifyCustomer({
			...shopifyCustomer,
			...(defaultAddress.id === id && {
				defaultAddress: updatedAddress,
			}),
			addresses: nextAddressesState,
		});

		return updatedAddress;
	};

	const deleteShopifyAddress = async (id, accessToken) => {
		const { accessToken: storeToken } = auth.shopifyCustomerToken;
		const customerToken = accessToken || storeToken;
		const deletedAddressId = await shopify.deleteAddress(customerToken, id);
		const nextAddressesState = shopifyCustomer.addresses.filter(
			(a) => a.id !== id
		);

		setShopifyCustomer({
			...shopifyCustomer,
			addresses: nextAddressesState,
		});

		return deletedAddressId;
	};

	const setDefaultShopifyAddress = async (
		id,
		accessToken,
		shouldForce = false
	) => {
		const { accessToken: storeToken } = auth.shopifyCustomerToken;
		const customerToken = accessToken || storeToken;
		const defaultAddress = shopifyCustomer.defaultAddress || {};

		if (defaultAddress.id === id && !shouldForce) {
			return shopifyCustomer;
		}

		const updatedCustomer = await shopify.updateDefaultAddress(
			customerToken,
			id
		);
		setShopifyCustomer({ ...shopifyCustomer, ...updatedCustomer });

		return updatedCustomer;
	};

	const getAuth0Profile = async (shouldForce = false) => {
		const { accessToken, profile = {} } = auth;

		if (profile.hasFetched && !shouldForce) {
			return profile;
		}

		const auth0Profile = await auth0.userInfo(accessToken);
		const normalizedProfile = normalize.profile.fromAuth0(auth0Profile, {
			hasFetched: true,
		});
		const nextAuthState = { ...auth, profile: normalizedProfile };
		setAuth(nextAuthState);

		return normalizedProfile;
	};

	const populateStateWithDb = async (shouldForce = false) => {
		const { idToken, accessToken, shopifyCustomerToken } = auth;
		const customerAccessToken = shopifyCustomerToken.accessToken;

		if (!idToken || !accessToken || !customerAccessToken) {
			return {};
		}

		if (isSeeded && !shouldForce) {
			return { isSeeded, formFields, shopifyCustomer, practitioner, mapPolicy };
		}

		const nextFormFields = { ...formFields };
		const salesforceClient = new salesforce.Client({ idToken, accessToken });
		const [dbPractitioner, dbMapPolicy, dbShopifyCustomer] =
			await Promise.allSettled([
				salesforceClient.getCurrentPractitioner(),
				salesforceClient.getCurrentMapPolicy(),
				shopify.getCustomer(customerAccessToken),
			]);

		if (dbPractitioner.status === 'fulfilled') {
			nextFormFields.interests = dbPractitioner.value.interests || [];
			nextFormFields.comments = dbPractitioner.value.additionalComments || '';
			setPractitioner({ ...dbPractitioner.value, hasFetched: true });
		}

		if (dbMapPolicy.status === 'fulfilled') {
			const dbMapPolicyValue = dbMapPolicy.value;
			const { dba, onlineStorefronts, fulfillmentCenters } = dbMapPolicyValue;
			const mailing = {
				address1: dba.addressPhysical.address1,
				address2: dba.addressPhysical.address2,
				city: dba.addressPhysical.city,
				state: dba.addressPhysical.provinceCode,
				zip: dba.addressPhysical.zip,
			};

			const billing = {
				address1: dba.addressMailing.address1,
				address2: dba.addressMailing.address2,
				city: dba.addressMailing.city,
				state: dba.addressMailing.provinceCode,
				zip: dba.addressMailing.zip,
			};

			const dbaInfo = {
				...mailing,
				company: dba.addressPhysical.company,
				email: dba.addressPhysical.email,
				phone: dba.addressPhysical.phone,
				billing: {
					...billing,
					sameAsPhysical: isEqual(mailing, billing),
				},
			};

			const defaultAddress = dbShopifyCustomer?.value?.defaultAddress || {};
			const defaultFulfillmentCenter = {
				company: defaultAddress.company,
				address1: defaultAddress.address1,
				address2: defaultAddress.address2,
				city: defaultAddress.city,
				provinceCode: defaultAddress.provinceCode,
				zip: defaultAddress.zip,
				phone: defaultAddress.phone,
			};

			const isNotDBA =
				dbShopifyCustomer?.value?.addresses?.some((a) => {
					const addressMailing = {
						address1: a.address1,
						address2: a.address2,
						city: dbaInfo.city,
						provinceCode: a.provinceCode,
						zip: a.zip,
						company: a.company,
						phone: a.phone,
					};

					return isEqual(dbaInfo.mailing, addressMailing);
				}) || false;

			nextFormFields.dbaInfo = dbaInfo;
			nextFormFields.fulfillmentCenters = fulfillmentCenters.map((c) => ({
				...c,
				state: c.provinceCode,
			}));
			nextFormFields.onlineStorefronts = onlineStorefronts;
			nextFormFields.isResellingOnline = !!onlineStorefronts.length;
			nextFormFields.isFulfillmentCenter =
				fulfillmentCenters.length === 1 &&
				isEqual(fulfillmentCenters[0], defaultFulfillmentCenter);
			nextFormFields.isDBA = dbaInfo.billing.sameAsPhysical && isNotDBA;
			nextFormFields.agreeToMapPolicy = true;
			setMapPolicy({ ...dbMapPolicy.value, hasFetched: true });
		}

		if (dbShopifyCustomer.status === 'fulfilled') {
			const defaultAddress = dbShopifyCustomer.value.defaultAddress || {};
			const secondaryAddress =
				dbShopifyCustomer.value.addresses?.[1] || defaultAddress;
			const shippingAddress = normalize.address.fromShopify(defaultAddress);
			const billingAddress = normalize.address.fromShopify(secondaryAddress);
			nextFormFields.billingAddress = billingAddress;
			nextFormFields.shippingAddresses = [shippingAddress];
			nextFormFields.isBillingSameAsShipping = isEqual(
				shippingAddress,
				billingAddress
			);

			setShopifyCustomer({ ...dbShopifyCustomer.value, hasFetched: true });
		}

		setFormFields(nextFormFields);
		setIsSeeded(true);

		return {
			isSeeded: true,
			formFields: nextFormFields,
			shopifyCustomer: dbShopifyCustomer.value,
			practitioner: dbPractitioner.value,
			mapPolicy: dbMapPolicy.value,
		};
	};

	const registrationRoutineS1A = async (data) => {
		const { companyName, phone, emailOptIn = false } = data;

		// Case when user is already authenticated
		const userMetadata = {
			companyName,
			emailOptIn,
			phone: normalize.phone(phone),
			status: REGISTRATION_STATUS.INCOMPLETE,
		};
		const user = normalize.user.toAuth0(data, userMetadata);
		const authUser =
			auth.isAuthenticated && auth.accessToken
				? auth
				: await auth0.loginOrRegister(user);
		const { accessToken } = authUser;

		const auth0Profile = await auth0.userInfo(accessToken);
		const profile = normalize.profile.fromAuth0(auth0Profile, {
			hasFetched: true,
		});
		const nextFormFields = { ...formFields, ...data };
		const nextAuthState = {
			...auth,
			...authUser,
			profile,
			isAuthenticated: true,
			isAuthenticating: false,
		};
		setFormFields(nextFormFields);
		setAuth(nextAuthState);
		window.localStorage.setItem(
			AUTH_STORAGE_KEY,
			JSON.stringify(nextAuthState)
		);

		return nextAuthState;
	};

	const registrationRoutineS1B = async (data) => {
		const {
			companyName,
			email,
			firstName,
			isBillingAndAccountEmailSame,
			lastName,
			phone,
			website,
			practiceType = {},
			specialty = {},
			emailOptIn = false,
			billingEmail: email2,
		} = data;

		const cbAuthState = data.auth || auth;
		const normalizedEmail = email.toLowerCase();
		const normalizedEmail2 = email2.toLowerCase();
		const normalizedBillingEmail = isBillingAndAccountEmailSame
			? normalizedEmail
			: normalizedEmail2;
		const { idToken, accessToken, profile } = cbAuthState;
		profile.shopify = await refreshMultipassToken(idToken)(profile.shopify);
		const { multipassToken } = profile.shopify;
		const shopifyCustomerToken = await shopify.createCustomerTokenWithMultipass(
			multipassToken
		);

		const { customer } = await shopify
			.updateCustomer(shopifyCustomerToken.accessToken, {
				phone: normalize.phone(phone) || null,
				acceptsMarketing: emailOptIn,
			})
			.catch((error) => {
				console.error(error.json || error);
				return shopify.updateCustomer(shopifyCustomerToken.accessToken, {
					acceptsMarketing: emailOptIn,
				});
			});

		const shopifyCustomerId = customer.id.split('/').pop();
		const practitionerData = {
			companyName,
			shopifyCustomerId,
			emailOptIn,
			firstName,
			lastName,
			phone,
			website,
			email: normalizedEmail,
			billingEmail: normalizedBillingEmail,
			practiceType: practiceType.value,
			specialty: specialty.value,
		};

		await putPractitioner(practitionerData, { idToken, accessToken });
		klaviyo.track('onboarding_step_practitioner_submitted', practitionerData);

		const customerProperties = {
			customer_address_1: customer?.defaultAddress?.address1 || '',
			customer_address_2: customer?.defaultAddress?.address2 || '',
			customer_city: customer?.defaultAddress?.city || '',
			customer_country: customer?.defaultAddress?.country || '',
			customer_email: email || '',
			customer_first_name: firstName || '',
			customer_id: gidUrlToId(customer?.id) || '',
			customer_last_name: lastName || '',
			customer_order_count: customer?.numberOfOrders || '',
			customer_phone: normalize.phone(phone) || '',
			customer_province: customer?.defaultAddress?.province || '',
			customer_province_code: customer?.defaultAddress?.provinceCode || '',
			customer_zip: customer?.defaultAddress?.zip || '',

			// The following field is required
			visitor_type: "logged_in"
		};

		window.ElevarDataLayer = window.ElevarDataLayer ?? [];
		window.ElevarDataLayer.push({
			event: "dl_sign_up",
			user_properties: customerProperties
		});

		const nextFormFields = { ...formFields, ...data };
		const nextAuthState = { ...cbAuthState, profile, shopifyCustomerToken };

		setFormFields(nextFormFields);
		setAuth(nextAuthState);
		window.localStorage.setItem(
			AUTH_STORAGE_KEY,
			JSON.stringify(nextAuthState)
		);

		return nextAuthState;
	};

	const registrationRoutineS2 = async (data) => {
		const {
			isBillingSameAsShipping,
			billingAddress = {},
			shippingAddress = {},
		} = data;
		const nextFormFields = {
			...formFields,
			isBillingSameAsShipping,
			billingAddress,
			shippingAddresses: [shippingAddress],
		};
		const normalizedShippingAddress =
			normalize.address.toShopify(shippingAddress);
		const normalizedBillingAddress =
			normalize.address.toShopify(billingAddress);
		const isEqualAddress = isEqual(
			normalizedBillingAddress,
			normalizedShippingAddress
		);
		const putShopifyShipping = shippingAddress.shopifyId
			? updateShopifyAddress.bind(null, shippingAddress.shopifyId)
			: createShopifyAddress;
		await postBusiness(null, null, true);
		const shopifyShippingAddress = await putShopifyShipping(
			normalizedShippingAddress
		);
		const { id: shippingAddressId } = shopifyShippingAddress;
		nextFormFields.shippingAddresses[0].shopifyId = shippingAddressId;

		if (!isEqualAddress && !isBillingSameAsShipping) {
			const putShopifyBilling =
				billingAddress.shopifyId &&
				billingAddress.shopifyId !== shippingAddress.shopifyId
					? updateShopifyAddress.bind(null, billingAddress.shopifyId)
					: createShopifyAddress;
			const shopifyBillingAddress = await putShopifyBilling(
				normalizedBillingAddress
			);
			const { id: billingAddressId } = shopifyBillingAddress;
			nextFormFields.billingAddress.shopifyId = billingAddressId;
		}

		// Shipping address is considered the default address
		await setDefaultShopifyAddress(shippingAddressId);

		klaviyo.track('onboarding_step_business_submitted', {
			billingAddress: normalizedBillingAddress,
			shippingAddress: normalizedShippingAddress,
		});

		setFormFields(nextFormFields);
	};

	const registrationRoutineS3 = async (data) => {
		const {
			agreeToMapPolicy,
			dbaInfo,
			fulfillmentCenters,
			isDBA,
			isFulfillmentCenter,
			isResellingOnline,
			onlineStorefronts,
		} = data;

		const defaultAddress = shopifyCustomer.defaultAddress || {};

		const nextFormFields = {
			...formFields,
			agreeToMapPolicy,
			dbaInfo,
			fulfillmentCenters,
			isDBA,
			isFulfillmentCenter,
			isResellingOnline,
			onlineStorefronts,
		};

		const dbaPayload = {
			address_physical: {
				company: dbaInfo.company,
				address1: dbaInfo.address1,
				address2: dbaInfo.address2,
				city: dbaInfo.city,
				province_code: dbaInfo.state,
				zip: dbaInfo.zip,
				phone: dbaInfo.phone,
				email: dbaInfo.email,
			},
		};

		if (dbaInfo.billing.sameAsPhysical) {
			dbaPayload.address_mailing = {
				address1: dbaInfo.address1,
				address2: dbaInfo.address2,
				city: dbaInfo.city,
				province_code: dbaInfo.state,
				zip: dbaInfo.zip,
			};
		} else {
			dbaPayload.address_mailing = {
				address1: dbaInfo.billing.address1,
				address2: dbaInfo.billing.address2,
				city: dbaInfo.billing.city,
				province_code: dbaInfo.billing.state,
				zip: dbaInfo.billing.zip,
			};
		}

		const onlineStorefrontsPayload = isResellingOnline ? onlineStorefronts : [];
		const fulfillmentCentersPayload = [];

		if (isResellingOnline && isFulfillmentCenter) {
			fulfillmentCentersPayload.push({
				company: defaultAddress.company,
				address1: defaultAddress.address1,
				address2: defaultAddress.address2,
				city: defaultAddress.city,
				province_code: defaultAddress.provinceCode,
				zip: defaultAddress.zip,
				phone: defaultAddress.phone,
			});
		}

		if (isResellingOnline && !isFulfillmentCenter) {
			fulfillmentCenters
				.filter((f) => f?.address1)
				.forEach((f) => {
					const item = {
						company: f.company,
						address1: f.address1,
						address2: f.address2,
						city: f.city,
						province_code: f.state,
						zip: f.zip,
						phone: f.phone,
						email: f.email,
					};

					fulfillmentCentersPayload.push(item);
				});
		}

		const payload = {
			dba: dbaPayload,
			online_storefronts: onlineStorefrontsPayload,
			fulfillment_centers: fulfillmentCentersPayload,
		};

		await putMapPolicy(payload);
		setFormFields(nextFormFields);
	};

	const onboardLicense = async (license) => {
		const licenseIsFile = license instanceof File;

		if (licenseIsFile) {
			await putLicense(license);
			klaviyo.track('onboading_step_documents_license_submitted', {
				name: license.name,
			});
		}
	};

	const onboardCertificate = async (certificate) => {
		const certificateIsFile = certificate instanceof File;

		if (certificateIsFile) {
			await putCertificate(certificate);
			klaviyo.track(
				'onboarding_step_documents_reseller_certificate_submitted',
				{
					name: certificate.name,
				}
			);
		}
	};

	const registrationRoutineS4 = async (data) => {
		const { certificate, license, comments, interests = [] } = data;
		const {
			accessToken,
			profile: { sub },
		} = auth;
		const certificateIsFile = certificate instanceof File;
		const licenseIsFile = license instanceof File;
		const practitionerData = {
			interests,
			additionalComments: comments,
		};

		await putPractitioner(practitionerData, undefined, true);

		const userMetadata = { status: REGISTRATION_STATUS.COMPLETE };
		const auth0Profile = await auth0.patchUserMetadata(
			accessToken,
			sub,
			userMetadata
		);
		const profile = normalize.profile.fromAuth0(auth0Profile, {
			hasFetched: true,
		});
		setAuth({ ...auth, profile });
		setFormFields({ ...formFields, ...data });

		klaviyo.track('onboarding_step_documents_submitted', {
			...(certificateIsFile && { certificate: { name: certificate.name } }),
			...(licenseIsFile && { license: { name: license.name } }),
		});
	};

	useEffect(() => {
		(async () => {
			const { isAuthenticated } = auth;
			if (!authLoading) {
				if (isAuthenticated) {
					const fetchedCustomer = await Promise.all([
						fetchShopifyCustomer().catch((error) => {
							console.log(error.json || error);
						}),
					]);
					if (fetchedCustomer.length) {
						const customer = fetchedCustomer?.[0] || {};
						setUserProperties({
							customer_address_1: customer?.defaultAddress?.address1 || '',
							customer_address_2: customer?.defaultAddress?.address2 || '',
							customer_city: customer?.defaultAddress?.city || '',
							customer_country: customer?.defaultAddress?.country || '',
							customer_email: auth?.profile?.email || '',
							customer_first_name: customer?.firstName || '',
							customer_id: gidUrlToId(customer?.id) || '',
							customer_last_name: customer?.defaultAddress?.lastName || '',
							customer_order_count: customer?.numberOfOrders || '',
							customer_phone: normalize.phone(customer?.defaultAddress?.phone) || '',
							customer_province: customer?.defaultAddress?.province || '',
							customer_province_code: customer?.defaultAddress?.provinceCode || '',
							customer_zip: customer?.defaultAddress?.zip || '',
							// The following field is required
							visitor_type: "logged_in"
						});
					}
				} else {
					setUserProperties({
						visitor_type: "guest"
					});
				}
				setUserPropertiesLoaded(true);
			}
		})();
	}, [authLoading]);

	const providerState = {
		auth,
		business,
		documentStatus,
		formFields,
		isInitialized,
		isInitializing,
		authLoading,
		userProperties,
		userPropertiesLoaded,
		isSeeded,
		mapPolicy,
		practitioner,
		shopifyCustomer,
	};

	const providerActions = {
		changePassword,
		signIn,
		signOut,
		registrationRoutineS1A,
		registrationRoutineS1B,
		registrationRoutineS2,
		registrationRoutineS3,
		registrationRoutineS4,
		onboardLicense,
		onboardCertificate,
		setFormFields,
		resetForm,
		populateStateWithDb,
		fetchPractitioner,
		putPractitioner,
		fetchBusiness,
		// putBusiness,
		// fetchDocumentStatus,
		// putLicense,
		// putCertificate,
		fetchShopifyCustomer,
		createShopifyAddress,
		updateShopifyAddress,
		deleteShopifyAddress,
		setDefaultShopifyAddress,
		getAuth0Profile,
		sendVerificationEmail,
	};

	const providerValue = useMemo(() => ({
		state: providerState,
		actions: providerActions,
	}), [providerState]);

	return (
		<AuthContext.Provider value={providerValue}>
			{children}
		</AuthContext.Provider>
	);
};
